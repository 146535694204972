import Cookies from 'universal-cookie';
import {doGet} from "./rest";

const cookies = new Cookies();


const getCookies = async () =>{
    let response = await doGet("auth-cookie/cookie")
    cookies.set("auth", JSON.stringify(response), {
        expires: 0,
        path: "/"
    })
}

const clearCookies = async () =>{
    await cookies.remove("auth",{
        path: "/"
    })
}

const hasAuthority = async (authority) =>{
    let authCookie = cookies.get("auth")
    if(authCookie === "null" || authCookie == null){
        await getCookies()
        authCookie = cookies.get("auth")
    }
    return authCookie.includes(authority)
}

export {
    hasAuthority,
    clearCookies,
    getCookies
}
