import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import React from 'react';
import CopyFooter from "./CopyFooter";

function Marketplace(){
    const [user, loading] = useAuthState(auth);
    const [media, setMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);

    useEffect(()=>{
        document.title = `Marketplace | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1){
            doGet("marketplace/").then((data)=>{
                setMedia(data);
                setIsLoading(false);
            })
            setCount(1)
        }

    }, [loading, user, navigate])

    return(<div>
        <div className={"form"}>
            {Heading("The Marketplace")}
            <div id="content" className="padding-content">
                {isLoading ? Loading() : <div><div className={"login"}>
                    <p>Do you have a item(s) you no longer need? Post a picture and find someone else who may want it.</p>
                <br/><br/>

                    <div className={"flex-photos"}>
                        {media.map(item=>(
                            <div className={"gallery-item"}>
                                <a href={"/marketplace-item/"+item.uid}>
                                    <img className={'photo-gallery-photo'} src={`${process.env.REACT_APP_URL}/uploads/marketplace/thumbs/`+item.pthumb} alt="img"/>
                                    <div>
                                        <b>{item.ptitle}</b>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div>
                        <button className="btn" onClick={()=>{
                            navigate("/marketplace-add")
                        }}>+add</button>
                    </div>
                </div>

                </div>}
            </div>
        </div>
        {CopyFooter()}
    </div>)
}

export default Marketplace
