import React from "react";


function Loading() {
    return (
        <div className="loadingoverlay">
            <div className="loadingicon"></div>
        </div>
    );
}

export {
    Loading
}