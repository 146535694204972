import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doPOst} from "./rest";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import Player from "./Player";
import {format, formatDistance, formatRelative, parse, subDays} from 'date-fns'

function CelebrateALife() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [month, setMonth] = useState("")
    const [monthNumber, setMonthNumber] = useState(0)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [year, setYear] = useState(0)
    const [people, setPeople] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [display, setDisplay] = useState(false)
    const [person, setPerson] = useState(null)
    useEffect(()=>{
        document.title = `Celebrate A Life | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        const date = new Date();
        setYear(date.getFullYear())
        setMonth(monthNames[date.getMonth()])
        setMonthNumber(date.getMonth())
        doPOst("celebrations/deaths-new-new").then((data)=>{
            setPeople(data)
            setIsLoading(false)
        })
    }, [loading, user, navigate])

    const toggleDisplay = (person) => {
        setPerson(person)
        setDisplay(!display)
    }
    const getPhotoFull = (person)=>{
        if(person.photoFilename != null && person.photoFilename !== ""){
            return <img alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.photoFilename} width={500}/>
        }
        if(person.residentGender === "female"){
            return <img src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width={500}/>
        }
        return <img src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={500}/>
    }


    const changeDateForward = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const dateChange = new Date(year, monthNumber, 1)
        const newDate = new Date(dateChange.setMonth(dateChange.getMonth()+1))
        const formDate = new FormData()
        const displayMont = newDate.getMonth()+1
        const toSend = newDate.getFullYear().toString()+("0"+displayMont).slice(-2).toString()
        formDate.set("date", toSend)
        const resp = await doPOst("celebrations/deaths-new-new", formDate)
        setMonthNumber(newDate.getMonth())
        setMonth(monthNames[newDate.getMonth()])
        setYear(newDate.getFullYear())
        setPeople(resp)
        setIsLoading(false)
    }
    const changeDateBack = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const dateChange = new Date(year, monthNumber, 1)
        const newDate = new Date(dateChange.setMonth(dateChange.getMonth()-1))
        const formDate = new FormData()
        const displayMont = newDate.getMonth()+1
        const toSend = newDate.getFullYear().toString()+("0"+displayMont).slice(-2).toString()
        formDate.set("date", toSend)
        const resp = await doPOst("celebrations/deaths-new-new", formDate)
        setMonthNumber(newDate.getMonth())
        setMonth(monthNames[newDate.getMonth()])
        setYear(newDate.getFullYear())
        setPeople(resp)
        setIsLoading(false)
    }

    const getPhoto = (person)=>{
        if(person.photoFilename != null && person.photoFilename !== ""){
            return <img alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.photoFilename} width={"175"}/>
        }
        if(person.residentGender === "female"){
            return <img src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" alt={"photo"} width={"175"}/>
        }
        return <img src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"175"}/>
    }

    const formatDate = (date) =>{
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);
        const dateObject = new Date(month+"/"+day+"/"+year)
        return dateObject.getMonth()+1+"/"+dateObject.getDate()+"/"+dateObject.getFullYear()
    }

    const serviceDate = (date) => {
        return format(parse(date, 'MM-dd-yyyy hh:mm a', new Date()), "eeee LLLL d, yyyy 'at' hh:mm a ")
    }


    return(<div>
        <div className={"form"}>
            {Heading("Celebrate A Life")}
            <div className={"padding-content"}>
                <div className={"login"}>
                    <h3>
                        <a id={"back"} onClick={changeDateBack} href="#"><FontAwesomeIcon icon={faArrowCircleLeft}></FontAwesomeIcon></a>
                        {month} {year}
                        <a id={"forward"} onClick={changeDateForward} href="#"><FontAwesomeIcon icon={faArrowCircleRight}></FontAwesomeIcon></a>
                    </h3>

                <br/>
                <div className={"flex-photos cursor-pointer"}>
                    {people.map(person=>(<div className={"gallery-item"} onClick={()=>toggleDisplay(person)}>
                        {getPhoto(person)}
                        {person.fname} {person.lname}<br/>
                        {person.residentLocations.homeNumber} {person.residentLocations.address}<br/>
                        {formatDate(person.residentBirth)}-{formatDate(person.datePassed)}<br/>
                        {person.residentLocations.neighborhood.neighborHoodCommunityName}
                    </div>))}
                </div>
                </div>
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
        {display ?<Player
        content={<>
            <div>
                {getPhotoFull(person)}<br/>
                <div className={"text-left black-text"}>
                    <b>Name: </b>{person.fnameDisplay} {person.lname}
                    <div><b>Address: </b>{person.residentLocations.homeNumber} {person.residentLocations.address}</div>
                    <div><b>Location: </b>{person.residentLocations.neighborhood.neighborHoodCommunityName}</div>
                    <br/>
                    <div>{formatDate(person.residentBirth)}-{formatDate(person.datePassed)}</div>
                    {person.deathInformation != null ? <div>
                        <br/>
                        <div className={'max-width'}>
                            Service will be held
                            at {person.deathInformation.serviceLocation} on {serviceDate(person.deathInformation.serviceDate)}
                        </div>
                    </div> : ''}
                </div>
            </div>
        </>}
        handleClose={() => toggleDisplay(null)}
        >
        </Player>:""}
    </div>)


}
export default CelebrateALife
