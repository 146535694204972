import {useNavigate, useParams} from "react-router-dom";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import {useEffect, useState} from "react";
import React from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, getUid} from "./firebase";
import {doGet} from "./rest";

function CommunityConnectView() {
    let {id} = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [user, loading] = useAuthState(auth);
    const [connectsion, setConnection] = useState({})
    const [canDelete, setCanDelete] = useState(false)
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = `Community Connections | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet("community-connections/connections/"+id).then((data)=>{
            setConnection(data)
            if(data.createdId === getUid()){
                setCanDelete(true)
            }
            setIsLoading(false)
        })
    },[user, loading, navigate, id])

    const deleteGroup = async event =>{
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete this group and remove all of its members?")){
            setIsLoading(true)
            const response = await doGet("community-connections/group/delete/"+id)
            alert(response.response)
            if(response.success){
                navigate("/communityconnect")
            }
            setIsLoading(false)
        }
    }

    return(<div>
        <div className={"form"}>
            {Heading("Community Connections")}
            <div className={"login"}>
                {isLoading ? Loading() : <div>
                    <p>There are {connectsion.connections.length} people who are interested in "{connectsion.name}"</p>
                    <br/>
                    <br/>
                    Group Members
                    <br/>
                    <br/>
                    {connectsion.connections.map((val)=>(
                        <div>
                            <a href={"mailto:"+val.users.email}>{val.users.fname} {val.users.lname}</a> - {val.users.phone}
                            <br/>
                            <br/>
                        </div>
                    ))}
                </div>}
                {canDelete ? <div>
                    <br/>
                    <br/>
                    Group Admin : <a href='#' onClick={deleteGroup}>Delete Group</a>
                </div> : ""}
            </div>
        </div>

        {CopyFooter()}
    </div>)

}
export default CommunityConnectView
