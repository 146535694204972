import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';

function CatClub() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `Micro Communities | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
    },[loading, user, navigate])

    return(<div>
        <div className={"form"}>
            {Heading("Cat Club")}
            <div className={"login"}>
                <h3>Register your Cat</h3>
                <p>Use <a href={process.env.REACT_APP_URL+"/pdf/web/viewer.html?file=/files/gsv-cat-club-registry.pdf"}>this form</a> and send to Louise.</p>

                <br/><br/>

                <h3>Request Cat Care</h3>
                <p>Louise Landry: 571.296.2144<br/>Paula Cole: 908.752.2166</p>

                <br/><br/>

                <h3>Questions</h3>
                <p>Jennie: 610.574.1355<br/>Louise: 571.296.2144</p>

                <br/><br/>
                <h3>Medications & Cat Veterinarian Visit</h3>
                <p>Louise: 223.264.9146<br/>Karen Klein: 267.446.4426<br/>Darlene &amp; Ed Smith: 717.355.6397</p>
            </div>
        </div>
        {CopyFooter()}
    </div>)
}
export default CatClub
