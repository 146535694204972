import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFileText, faPhotoFilm, faSleigh, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {doGet, doPOst} from "./rest";
import {Loading} from "./Loading";
import React from 'react';
import CopyFooter from "./CopyFooter";

function CommunityEventAdmin() {
    const [user, loading] = useAuthState(auth);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState(0);
    const [communityEvent, setCommunityEvent] = useState({})
    const [category, setCategory] = useState([])
    const [newCategory, setNewCategory] = useState({})
    const [currentDocs, setCurrentDocs] = useState({})
    useEffect(()=>{
        document.title = `Edit Community Event | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_communityEvents").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        if(count < 1){
            loadEventTypes()
            loadCurrentDocs()
            setCount(1)
        }
    },[loading, user, navigate])

    const loadCurrentDocs = async () =>{
        const docs = await doGet("community-events/list-all")
        setCurrentDocs(docs)
    }
    const handleChange = event =>{
        setCommunityEvent(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }
    const handkeOtherChange = event =>{
        setNewCategory(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }
    const onFocus = event =>{
        event.target.type = "date";

    }
    const onSubmit = async event =>{
        event.preventDefault()
        if(communityEvent.title == null){
            alert("Title is required")
            return
        }
        if(communityEvent.cat == null){
            alert("Please select a category.")
            return
        }
        if(selectedFile == null){
            alert("file is required")
            return
        }
        setIsLoading(true)
        const formdate = new FormData();
        formdate.set("data", JSON.stringify(communityEvent))
        formdate.set("file", selectedFile)
        const resp = await doPOst("community-events/new-post", formdate)
        alert(resp.response)
        if(resp.success){
            setCommunityEvent({})
            setSelectedFile(null)
        }
        setIsLoading(false)
    }

    const onCategorySubmit = async event =>{
        event.preventDefault()
        if(newCategory.newCategory.length === 0){
            alert("Please enter a new category name")
            return
        }
        if(newCategory.count === ""){
            newCategory.count = 0
        }
        if(newCategory.count != null && isNaN(newCategory.count)){
            alert("Default Number of Listings must be a number!")
            return
        }
        setIsLoading(true)
        let formDate = new FormData();
        formDate.append("category", newCategory.newCategory)
        formDate.append("count", newCategory.count ?? 0)
        const setNew = await  doPOst("community-events/new-category", formDate)
        setCategory(setNew)
        alert("New Category has been added")
        setNewCategory({})
        setIsLoading(false)
    }

    const loadEventTypes = async () =>{
        const cat = await doGet("community-events/categories")
        setCategory(cat)
    }
    const deleteItem = async (id) =>{
        if(window.confirm("Are you sure you want to delete this item?  It cannot be undone.")){
            setIsLoading(true)
            const res = await doGet("community-events/delete/"+id)
            setCurrentDocs(res)
            setIsLoading(false)
        }
    }

    const renderDocs = () =>{
        return Object.keys(currentDocs).map((item, index)=>{
            return(<div>
                {currentDocs[item].map(items=>(
                    <div className={"flex"}><div className={"text-left top-bottom-padding-large"}>
                        <div><b>{items.title}</b></div>
                        <div>{item}</div>
                    </div><div className={"trash-container"}><div onClick={() => deleteItem(items.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div> </div>
                ))}
            </div>)
        })
    }

    return(<div>
        <div className={"form"}>
            {Heading("Add Community Event")}
            <div className={"login"}>
                <form onSubmit={onSubmit}>
                    <ul>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="title"
                                className="text"
                                placeholder="Title"
                                value={communityEvent.title || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="date"
                                className="text"
                                placeholder="Publish Date"
                                value={communityEvent.date || ""}
                                onChange={handleChange}
                                onFocus={onFocus}
                                required
                            />
                        </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faSleigh}></FontAwesomeIcon>
                                </span>
                        <select
                            name="cat"
                            className="text"
                            value={communityEvent.cat || ""}
                            onChange={handleChange}
                            required
                        >
                            <option></option>
                            {category.map(item =>(
                                <option value={item.id}>{item.title}</option>
                            ))}
                        </select>
                    </li>
                        <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Item</button>
                        </li>
                </ul>
                </form>
                <p>Add Category</p>
                <form onSubmit={onCategorySubmit}>
                    <ul>
                    <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="newCategory"
                            className="text"
                            placeholder="New Category Title"
                            value={newCategory.newCategory || ""}
                            onChange={handkeOtherChange}
                            required
                        />
                    </li>
                    <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                        <input
                            type="number"
                            name="count"
                            className="text"
                            placeholder="Default Number of Listings"
                            value={newCategory.count || ""}
                            onChange={handkeOtherChange}
                        />
                    </li>
                    <li>
                        <button name="gsvradd" value="Add Photo" className="btn">Add Category</button>
                    </li>
                    </ul>
                </form>
                <p>Manage Documents</p>
                {renderDocs()}
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
    </div>)
}
export default CommunityEventAdmin
