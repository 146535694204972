import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {doGet} from "./rest";
import {Loading} from "./Loading";

function MicroCommunities() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [communities, setCommunities] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        document.title = `Micro Communities | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet("micro-communities").then((data)=>{
            setCommunities(data)
            setIsLoading(false)
        });
    },[loading, user, navigate])
    const onIconClick = (data)=>{
        if(data.type === 'WEBSITE'){
            window.open(data.data, '_blank')
        }
        if(data.type === "PAGE"){
            navigate(`/micro-community/${data.id}`)
        }
    }

    return (<div>
        <div className={"form"}>
            {Heading("Micro Communities")}
            <div className={"login"}>
                <p>
                    Garden Spot offers a variety of micro-communities to help you connect with people who have similar interests and skills.
                </p>
                <br/>
                {isLoading ? Loading() : <div className={"flex-even"}>
                    {communities.map(comm=>(
                        <a onClick={()=>onIconClick(comm)}>
                            <img className={'new-button-size'} src={`data:image/png;base64,${comm.icon}`}/>
                        </a>
                        )
                    )}
                </div>}
            </div>
        </div>
        {CopyFooter()}

    </div>)

}

export default MicroCommunities
