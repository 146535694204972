import {useAuthState} from "react-firebase-hooks/auth";
import {auth, logInWIthEmailAndPassword} from "./firebase";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHashtag, faLock, faMailBulk, faPerson} from "@fortawesome/free-solid-svg-icons";
import {postJsonNoLogin} from "./rest";

function SignUp() {
    let { type } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [future, setFuture] = useState({});
    const [futureValidate, setFutureValidate] = useState(false)
    useEffect(()=>{
        document.title = `Create Account | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (user) return navigate("/dashboard");
    },[user, loading, navigate])

    const handleChange = event =>{

            setNewUser(prevState =>({
                ...prevState,
                [event.target.name] : event.target.value
            }))


    }

    const handleFutureChange = event => {
        setFuture(prevState => ({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const validateFutureKey = async () =>{
        if(future.registrationCode == null){
            alert("Please enter a registration code to continue")
            return;
        }
        setIsLoading(true)
        const resp = await postJsonNoLogin("sign-up/future/validate-key", {"key":future.registrationCode})
        if(resp == null){
            alert("We were unable to validate your registration code.")
        }else{
            if(resp.didSignUp != null && resp.didSignUp){
                alert("It looks like this code was already used to setup an account.  Please use the forgot password option to reset your password if you do not know it. ")
            }else{
                setFuture(resp)
                setFutureValidate(true)
            }
        }
        setIsLoading(false)
    }


    const emptyOrNull =(val) => {
        return val == null || val === '';

    }
    const validateEmail = (email) =>{
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    const submitForm = async event =>{
        event.preventDefault()
        if(emptyOrNull(newUser.fname)){
            alert("First Name is required")
            return
        }
        if(emptyOrNull(newUser.lname)){
            alert("Last Name is required")
            return
        }
        if(!validateEmail(newUser.email)){
            alert("A valid email address is required")
            return
        }
        if(emptyOrNull(newUser.code)){
            alert("Please enter a registration code to continue.")
            return
        }
        if(emptyOrNull(newUser.password) || newUser.password.length < 6){
            alert("Password must be at least 6 characters long.")
            return
        }
        setIsLoading(true)
        const response = await postJsonNoLogin("sign-up/new-account", newUser)
        alert(response.response)
        if(response.success){
         const res = await logInWIthEmailAndPassword(newUser.email, newUser.password, true)
            if(res != null){
                alert(res);
            }
        }
        setIsLoading(false)
    }
    const submitFutureRequest = async () => {
        if(future.password.length < 6){
            alert("Passwords must be at least 6 characters long.")
            return;
        }
        let user = {}
        user.fname = future.firstName
        user.lname = future.lastName
        user.email = future.emailAddress
        user.code = future.registrationCode
        user.password = future.password
        setIsLoading(true)
        const response = await postJsonNoLogin("sign-up/future/new-account", user)
        alert(response.response)
        if(response.success){
            const res = await logInWIthEmailAndPassword(user.email, user.password, true)
            if(res != null){
                alert(res);
            }
        }
    }

    if(type !== null){
        if(type === "resident"){
            return(<div>
                <div className={"form"}>
                    <div className="header-login">
                        <h2>Create a New Account</h2>
                    </div>
                    <div className={"login"}>
                        <form onSubmit={submitForm}>
                            <ul>
                                <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                    <input
                                        type="text"
                                        name="fname"
                                        className="text"
                                        placeholder="First Name"
                                        value={newUser.fname || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </li>
                                <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                    <input
                                        type="text"
                                        name="lname"
                                        className="text"
                                        placeholder="Last Name"
                                        value={newUser.lname || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </li>
                                <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                                </span>
                                    <input
                                        type="text"
                                        name="email"
                                        className="text"
                                        placeholder="Email Address"
                                        value={newUser.email || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </li>
                                <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                                    <input
                                        type="text"
                                        name="code"
                                        className="text"
                                        placeholder="Registration Code"
                                        value={newUser.code || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </li>
                                <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                                    <input
                                        type="password"
                                        name="password"
                                        className="text"
                                        placeholder="Enter New Password"
                                        value={newUser.password || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </li>
                                <li>
                                    <input type="submit" name="gsvrupdate" value="Create Account" className="btn"/>
                                </li>
                            </ul>
                        </form>

                    </div>
                </div>
                {isLoading ? Loading() : ""}
                {CopyFooter()}
            </div>)
        }else if(type === "future"){
            return(<div>
                <div className={"form"}>
                <div className="header-login">
                  <h2>Create a New Account</h2>
                </div>
                <div className={"login"}>
                    <ul>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faHashtag}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="registrationCode"
                                className="text"
                                placeholder="Registration code"
                                value={future.registrationCode || ""}
                                onChange={handleFutureChange}
                                required
                            />
                        </li>
                        {futureValidate ?<div>
                            <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="text"
                                    placeholder="First Name"
                                    value={future.firstName || ""}
                                    onChange={handleFutureChange}
                                    required
                                    readOnly={true}
                                />
                            </li>
                            <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="lastName"
                                    className="text"
                                    placeholder="First Name"
                                    value={future.lastName || ""}
                                    onChange={handleFutureChange}
                                    required
                                    readOnly={true}
                                />
                            </li>
                            <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="emailAddress"
                                    className="text"
                                    placeholder="First Name"
                                    value={future.emailAddress || ""}
                                    onChange={handleFutureChange}
                                    required
                                    readOnly={true}
                                />
                            </li>
                            <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="password"
                                    name="password"
                                    className="text"
                                    placeholder="Create New Password"
                                    value={future.password || ""}
                                    onChange={handleFutureChange}
                                    required
                                />
                            </li>
                            <li>
                                If this information is not correct, and you verify you have typed your key correctly, please contact the resident services.
                            </li>
                        </div> : ''}
                        <li>
                            {futureValidate ? <input type="submit" onClick={()=>submitFutureRequest()} name="gsvrupdate" value="Create Account" className="btn"/> :
                                <input type="submit" onClick={()=>validateFutureKey()} name="gsvrupdate" value="Verify Code" className="btn"/>}
                        </li>
                    </ul>
                </div>
                </div>
                {isLoading ? Loading() : ""}
                {CopyFooter()}
            </div>)
        }
    }


    return(<div>
        <div className={"form"}>
            <div className="header-login">
                <h2>Create a New Account</h2>
            </div>
            <div className={"login"}>
              <p>If you are a current resident, with a 10 digit registration code use the option below.  If you do not have a code, you can get your code from the resident services desk.</p>
                <button onClick={()=> navigate("/signup/resident")} name="gsvradd" value="Add Photo" className="btn">Current Residents Click Here</button>
                <p>If you are a future resident with a 12 digit registration code use the option below.  If you do not have a code, please contact resident services. </p>
                <button onClick={()=> navigate("/signup/future")} name="gsvradd" value="Add Photo" className="btn">Future Residents Click Here</button>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}

export default SignUp
