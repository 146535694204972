import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {doGet, doPOst, postJson} from "./rest";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendar,
    faListNumeric,
    faPhotoFilm,
    faSleigh,
    faSort,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";

function PurposefulLivingAdmin() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [repository, setRepository] = useState({})
    const [newDocument, setNewDocument] = useState({})
    const [newCategory, setNewCategory] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)
    const [buttonTitle, setButtonTitle] = useState("Add Category")
    useEffect(()=>{
        document.title = `Purposeful Living Admin | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        hasAuthority("ROLE_communityEvents").then((res)=>{
            if(!res){
                navigate("/dashboard")
            }
        })
        doGet("purposeful-living/all/"+id).then((data)=>{
            setRepository(data)
            setIsLoading(false)
        })
    }, [user, loading, navigate])
    const handleChange = event =>{
        setNewDocument(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const handleChangeCategory = event =>{
        setNewCategory(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }
    const onClick = (location) =>{
        window.open(process.env.REACT_APP_URL+"/pdf/web/viewer.html?file=/files/"+location, ".blank")
    }
    const deleteCategory =  async (id) =>{
        if(window.confirm("Are you sure you want to delete this category?  All documents will be deleted as well.")) {
            setIsLoading(true)
            const deleteItem = await doGet("purposeful-living/delete/category/" + id)
            if(deleteItem.success){
                const reload = await doGet("purposeful-living/all/"+id)
                setRepository(reload)
            }else{
                alert("Something has gone wrong, we were unable to delete the category.")
            }
            setIsLoading(false)
        }
    }
    const deleteDocument = async (id2) =>{
        if(window.confirm("Are you sure you want to delete this?")) {
            setIsLoading(true)
            const deleteItem = await doGet("purposeful-living/delete/document/" + id2)
            if(deleteItem.success){
                const reload = await  doGet("purposeful-living/all/"+id)
                setRepository(reload)
            }else{
                alert("Something has gone wrong, we were unable to delete the document.")
            }
            setIsLoading(false)
        }
    }

    const setCategoryUpdateData = (update) => {
        setNewCategory({})
        setButtonTitle("Update Category")
        const updateNew = {'id':update.id, 'categoryName': update.categoryName, 'sortOrder': update.sortOrder, 'limit':update.limit}
        setNewCategory(updateNew)
    }
    const renderItems = () =>{
       if( repository.categories != null) {
           return repository.categories.map(item => (

                   <div>
                       <h2 onClick={()=> setCategoryUpdateData(item)}>{item.categoryName}<FontAwesomeIcon
                           onClick={() => deleteCategory(item.id)} icon={faTrashCan}></FontAwesomeIcon></h2>
                       <ul>
                           {item.documents.map(doc => (
                               <li>
                                   <div className={"flex"}><a href="javascript:void(0)"
                                                              onClick={() => onClick(doc.documentName)}>{doc.documentTitle}</a><FontAwesomeIcon
                                       onClick={() => deleteDocument(doc.id)} icon={faTrashCan}></FontAwesomeIcon></div>
                               </li>
                           ))}
                       </ul>
                   </div>
           ))
       }
    }
    const onFocus = event =>{
        event.target.type = "date";
    }

    const addCategory = async event =>{
        event.preventDefault()
        if(newCategory.length === 0){
            alert("Please enter a new category to continue.")
            return;
        }
        setIsLoading(true)
        const resp = await postJson("purposeful-living/category/add/"+id, newCategory)
        if(resp.toString().trim().toLowerCase() === "false"){
            alert("Unable to create new category, are you sure it does not already exist.")
        }else{
            const items = await doGet("purposeful-living/all/"+id)
            setRepository(items);
            setNewCategory({})
            event.target.reset()
            setButtonTitle("Add Category")
        }
        setIsLoading(false)
    }

    const submitForm = async event =>{
        event.preventDefault()
        if(selectedFile == null){
            alert("File is required.")
            return;
        }
        if(newDocument.documentTitle == null){
            alert("Please enter a title.")
            return
        }
        if(newDocument.date == null){
            alert("Please select a date.")
            return
        }
        if(newDocument.purposefulLivingCategories == null || newDocument.purposefulLivingCategories === ""){
            alert("Please select a category to continue.")
            return
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.set("documentTitle", newDocument.documentTitle)
        formData.set("date", newDocument.date)
        formData.set("file", selectedFile)
        const resp = await doPOst("purposeful-living/document/add/"+newDocument.purposefulLivingCategories, formData)
        alert(resp.response)
        if(resp.success){
            event.target.reset();
            setNewDocument({})
            setSelectedFile(null)
            const reload = await  doGet("purposeful-living/all/"+id)
            setRepository(reload)
        }
        setIsLoading(false)
    }

    return(<div>
        <div className={"form"}>
            {Heading(repository?.title ?? '')}
            <div className={"login"}>
                <p>Upload A New Document</p>
                <form onSubmit={submitForm}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="documentTitle"
                                className="text"
                                placeholder="Title"
                                value={newDocument.documentTitle || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>

                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                onFocus={onFocus}
                                className="text"
                                name="date"
                                placeholder="Date"
                                value={newDocument.date || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faSleigh}></FontAwesomeIcon>
                                </span>
                            <select
                                name="purposefulLivingCategories"
                                className="text"
                                value={newDocument.purposefulLivingCategories || ""}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select Category</option>
                                {repository?.categories != null ?repository.categories.map(item =>(
                                    <option value={item.id}>{item.categoryName}</option>
                                )):''}
                            </select>
                        </li>
                        <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Document</button>
                        </li>
                    </ul>
                </form>
                <p>Add A New Category</p>
                <form onSubmit={addCategory}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="categoryName"
                                className="text"
                                placeholder="Title"
                                value={newCategory.categoryName}
                                onChange={handleChangeCategory}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>
                                </span>
                            <input
                                type="number"
                                name="limit"
                                className="text"
                                placeholder="Max to show (0 is umlimited)"
                                value={newCategory.limit || 0}
                                onChange={handleChangeCategory}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faSort}></FontAwesomeIcon>
                                </span>
                            <select
                                name="sortOrder"
                                className="text"
                                placeholder="Sort By"
                                value={newCategory.sortOrder}
                                onChange={handleChangeCategory}
                                required
                            >
                                <option value="title">Title</option>
                                <option value="date">Date</option>
                            </select>
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">{buttonTitle}</button>
                        </li>
                    </ul>
                </form>
                <p>Documents</p>
                {renderItems()}
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)


}
export default PurposefulLivingAdmin
