import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {Loading} from "./Loading";
import Heading from "./Heading";
import React from 'react';
import CopyFooter from "./CopyFooter";
import {doGet} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons";
import {hasAuthority} from "./MyCookies";


function Weekly(){

    const [user, loading] = useAuthState(auth);
    const [media, setMedia] = useState([]);
    const [isLoading2, setIsLoading2] = useState(true);
    const navigate = useNavigate();
    const [count, setCount] = useState(0)
    const [weeklyAdmin, setWeeklyAdmin] = useState(false)
    useEffect(()=>{
        document.title = `The Weekly | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1) {
            doGet("weekly/").then((res) => {
                setMedia(res);
                setIsLoading2(false);
            })
            setCount(1)
        }
        hasAuthority("ROLE_weekly").then((res)=>{
            setWeeklyAdmin(res)
        })

    },[user, loading, navigate])


    return(
        <div>
            <div className="form">
                {Heading("The Weekly")}
            {isLoading2 ? Loading() : <div>

                    <div className="login">
                        <p>See the following documents for weekly announcements:</p>
                        <br/>
                        {media.map(items=>(
                            <div>
                                <a href={`${process.env.REACT_APP_URL}/pdf/web/viewer.html?file=/files/`+items.name} target="_blank">
                                    {items.title}
                                </a>
                                <br/><br/>
                            </div>
                        ))}
                    </div>


            </div>}

            </div>
            {weeklyAdmin ? <div className="sign logout-container flex">
                <a className={"button-icon"} href="/add-weekly">
                    <FontAwesomeIcon icon={faNotesMedical}></FontAwesomeIcon><br/>
                    Add Weekly
                </a>
            </div> : ""}
            {CopyFooter()}
        </div>
    )

}
export default Weekly
