import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar, faEdit,
    faHomeLgAlt,
    faHotTub,
    faListNumeric, faTrashCan,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import {doGet, postJson} from "./rest";
import {faFileText} from "@fortawesome/free-solid-svg-icons/faFileText";

function AddMedia(){
    const [user, loading] = useAuthState(auth);
    const [newMedia, setNewMedia] = useState({})
    const [newCategory, setNewCategory] = useState({})
    const [categories, setCategores] = useState([])
    const [showLoading, setShowLoading] = useState(true)
    const [media, setMedia] = useState({})
    const [count, setCount] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `Add Media | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_media").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        if(count < 1){
            loadCategories()
            setCount(1)
        }

    },[loading, user, navigate])

    const loadCategories = async () =>{
        const response = await doGet("media/categories")
        setCategores(response)
        await loadMedia()
        setShowLoading(false)
    }

    const loadMedia = async () =>{
        const media = await doGet("media/all")
        setMedia(media)
    }

    const handleSubmit = async event =>{
        event.preventDefault()
        if(newMedia.title.length === 0){
            alert("Title is required")
            return
        }
        if(!validateUrl(newMedia.url)){
            alert("Valid url is required.")
            return
        }
        if(newMedia.cat == null){
            alert("Please select a category")
            return
        }
        setShowLoading(true)
        const response = await postJson("media/new-media", newMedia)
        setMedia(response)
        setNewMedia({})
        setShowLoading(false)
    }

    const submitNewGroup = async event =>{
        event.preventDefault()
        if(newCategory.title.length === 0){
            alert("Please enter a new category title")
            return
        }
        setShowLoading(true)
        const response = await postJson("media/new-category", newCategory)
        setCategores(response)
        alert("New category has been added")
        setShowLoading(false)
    }

    function validateUrl(urlString){
      try {
            new URL(urlString);
        } catch (_) {
            return false;
        }
        return true;
    }

    const onFocus = event =>{
        event.target.type = "date";

    }

    const handleChange = event =>{
            setNewMedia(prevState =>({
                ...prevState,
                [event.target.name] : event.target.value
            }))
    }

    const handleMediaChange = event =>{
        setNewCategory(prevState=>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const deleteVideo = async (id) => {
        if(window.confirm("Are you sure you want to delete this item?  It cannot be undone.")){
            setShowLoading(true)
            const resp = await doGet("media/delete/"+id)
            setMedia(resp)
            setShowLoading(false)
        }
    }

    const onEdit = (id) =>{
        navigate("/edit-media/"+id)
    }

    const renderBody = () =>{
        return Object.keys(media).map((item, index) => {
            return (<div>
                {media[item].map(items=>(
                    <div className={"flex"}><div className={"text-left top-bottom-padding-large"}>
                        <div><b>{items.title}</b></div>
                        <div>{items.url}</div>
                        <div>{item}</div>
                    </div><div className={"trash-container"}><div onClick={() => onEdit(items.id)}><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></div><div className={"padding-content"}></div><div onClick={() => deleteVideo(items.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div></div>
                ))}
            </div>);
        })
    }

    return(<div>
        <div className={"form"}>
            {Heading("Video Gallery Admin")}
            <div className="login">
                {showLoading ? Loading() :<div>
                    <h3>Add Media</h3>
                    <form onSubmit={handleSubmit}>
                    <ul>

                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="title"
                                    className="text"
                                    placeholder="Media Title"
                                    value={newMedia.title || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faHotTub}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="url"
                                className="text"
                                placeholder="Media Url"
                                value={newMedia.url || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>

                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                onFocus={onFocus}
                                className="text"
                                name="date"
                                placeholder="Post Date"
                                value={newMedia.date || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>

                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faHomeLgAlt}></FontAwesomeIcon>
                                </span>
                            <select className={"text"} name={"cat"} value={newMedia.cat} onChange={handleChange}>
                                <option disabled={true} selected={true}>Select Category</option>
                                {categories.map(item=>(
                                    <option value={item.id}>{item.title}</option>
                                ))}
                            </select>
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add New Media</button>
                        </li>
                    </ul>
                </form>
                <h3>Add Category</h3>
                    <form onSubmit={submitNewGroup}>
                        <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="title"
                                    className="text"
                                    placeholder="Category Title"
                                    value={newCategory.title || ""}
                                    onChange={handleMediaChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="defaultListingNum"
                                    className="text"
                                    placeholder="Default Number of Listings"
                                    value={newCategory.defaultListingNum || ""}
                                    onChange={handleMediaChange}
                                />
                            </li>
                            <li>
                                <button name="gsvradd" value="Add Photo" className="btn">Add New Category</button>
                            </li>
                        </ul>
                    </form>
                    <h3>Media Items</h3>
                    <ul>
                    {showLoading ? <div></div> : renderBody()
                    }
                    </ul>
                </div>}

            </div>
        </div>
        {CopyFooter()}
    </div>)
}

export default AddMedia
