import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {postJsonNoLogin} from "./rest";
import React from 'react';

function ForgotPassword() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [email, setEmail] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    useEffect(()=>{
        document.title = `Forgot Password | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (user) return navigate("/dashboard");
    },[user, loading, navigate])

    const handleChange = event =>{
        setEmail(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(!validateEmail(email.email)){
            alert("A valid email address is required.")
            return;
        }
        setIsLoading(true)
        const resp = await postJsonNoLogin("sign-up/pw-reset", email)
        alert(resp.response)
        setIsLoading(false)
    }
    const validateEmail = (email) =>{
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    return (<div>
        <div className={"form"}>
            <div className="header-login">
                <h2>Forgot Password</h2>
            </div>
            <div className={"login"}>
                <p>Enter the email address associated with your Resident Portal account, then click Continue. We'll send you a link to a page where you can easily create a new password.</p>
                <form onSubmit={onSubmit}>
                    <ul>
                        <li className={"flex"}>
                        <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="email"
                            className="text"
                            placeholder="User Email Address"
                            value={email.email || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                        <li>
                            <input type="submit" name="gsvrupdate" value="Continue" className="btn"/>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}
export default ForgotPassword
