import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logInWIthEmailAndPassword } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons'
import "./Login.css";


function Login(){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [persistance, setPersistance] = useState(false);
    const [loginError, setLoginError] = useState(null)
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const handleChange = () =>{
        setPersistance(!persistance);
    }

    useEffect(() => {
        document.title = "Log In | "+process.env.REACT_APP_TITLE
        if(loading){
            return;
        }
        if(user)navigate("/dashboard")
    },[user, loading]);
    const handleSubmit = event => {
        event.preventDefault()
        logInWIthEmailAndPassword(email, password, persistance).then((res) =>{
            if(res != null){
                setLoginError(res)
            }

        })
    }

    return(
        <div className="form">
            <div className="header-login"><h2>{process.env.REACT_APP_TITLE}</h2></div>
            <div className="login">
                <img src={"/images/"+process.env.REACT_APP_LOGO} style={{maxWidth :'240px'}}/>
                {loginError != null ? <div id="login_error">{loginError}</div> : <div></div> }
                <div id="login_nconfirm"></div>
                <form onSubmit={handleSubmit}>
                <ul>
                     <li className="flex">       <span className="un">
                                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                            </span>

                    <input type="text"
                           name="username"
                     className="text"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                           placeholder="User Email"
                    />
                        </li><li className="flex">
                            <span className="un">
                                <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                            </span>
                            <input
                                name="password"                            type="password"
                            className="text"
                            placeholder="User Password"
                            onChange={(e) => setPassword(e.target.value)}
                            />
                        </li><li>

                            <button
                            className="btn"
                            onClick={
                                handleSubmit
                            }
                            >
                                Log In
                            </button>

                </li></ul>
            </form>
                <div className="top-bottom-padding">
                    <input
                        id="r"
                        type="checkbox"
                        onChange={handleChange}
                    />
                    <label htmlFor="r">Remember Me</label>
                </div>
                <div className="top-bottom-padding">
                            <a
                            href="/forgotpassword"
                            >Forgot Password?</a>
                </div>
                <div className="top-bottom-padding">
                    <a
                        href="/signup"
                    >Create Account</a>
                </div>

                       </div>

        </div>
    );
}
export default Login;
