import React, {useEffect, useState} from 'react';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css';
import {logOut} from "./firebase";
import {hasAuthority} from "./MyCookies";

export default props => {

    const [resident, setIsResident] = useState(false)

    useEffect(()=>{
        hasAuthority("ROLE_RESIDENT").then((res)=>{
            setIsResident(res)
        })
    }, [resident])

    return (
        <Menu>
            <a className="menu-item" href="/dashboard">
                Home
            </a>
            <a className="menu-item" href="/settings">
                User Profile
            </a>
            {resident ? <a className="menu-item" href="/vacations">
                Vacations
            </a> : ""}
            <a onClick={()=>logOut()} className="menu-item" href="/">
                Sign Out
            </a>
        </Menu>
    );
};
