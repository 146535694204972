import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate, useParams} from "react-router-dom";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMailBulk, faPerson} from "@fortawesome/free-solid-svg-icons";
import BackButton from "./BackButton";
import CopyFooter from "./CopyFooter";
import {doGet, doPOst} from "./rest";
import {Loading} from "./Loading";
import React from 'react';

function FutureResidentForm() {

    let { id } = useParams();


    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [newResident, setNewResident] = useState({})
    useEffect(()=>{
        document.title = `Future Resident Admin | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_users").then((resp)=>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        if(id != null){
            setIsLoading(true)
            doGet("user-admin/lookup-future/"+id).then((data)=>{
                setNewResident(data);
                setIsLoading(false)
            })
        }

    },[loading, user, navigate])

    const handleChange = event => {
        setNewResident(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSubmit = async event =>{
        event.preventDefault()
        if(newResident.firstName == null){
            alert("First name is required.")
            return
        }
        if(newResident.lastName == null){
            alert("Last name is required.")
            return
        }
        if(newResident.emailAddress == null){
            alert("Email address is required.")
            return
        }
        const formData = new FormData();
        for(let key in newResident){
            formData.append(key, newResident[key]);
        }
        setIsLoading(true)
        const resp = await doPOst("user-admin/future-residents/invite", formData);
        alert(resp.response)
        if(resp.success){
            setNewResident({})
            event.target.reset();
        }
        setIsLoading(false)
    }

    const updateUser = async event =>{
        event.preventDefault()
        if(newResident.firstName == null){
            alert("First name is required.")
            return
        }
        if(newResident.lastName == null){
            alert("Last name is required.")
            return
        }
        if(newResident.emailAddress == null){
            alert("Email address is required.")
            return
        }
        const formData = new FormData();
        for(let key in newResident){
            if(newResident[key] != null){
                formData.append(key, newResident[key]);
            }
        }
        setIsLoading(true)
        const resp = await doPOst("user-admin/future-residents/update", formData);
        alert(resp.response)
        if(resp.success){
            navigate("/future-residents")
        }
        setIsLoading(false)
    }

    if(id != null){
        return(<div>
            <div className="form">
                {Heading("Invite Resident")}
                <div className="login">
                    <form onSubmit={updateUser}>
                        <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="text"
                                    placeholder="First Name"
                                    value={newResident.firstName || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="lastName"
                                    className="text"
                                    placeholder="Last Name"
                                    value={newResident.lastName || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="emailAddress"
                                    className="text"
                                    placeholder="Email Address"
                                    value={newResident.emailAddress || ""}
                                    onChange={handleChange}
                                    required
                                    readOnly={true}
                                />
                            </li>
                            <li>
                                <button name="gsvradd" value="Add Photo" className="btn">Update</button>
                            </li>
                            <li>
                                Resident has signed up: {newResident.didSignUp ? "Yes" : "No"}
                            </li>
                            {newResident.didSignUp ? <li>
                                Sign Up Date: {newResident.signUpDate}
                            </li> : ''}
                            <li>
                                Resident Sign Up Key: {newResident.registrationCode}
                            </li>


                        </ul>
                    </form>
                </div>
                {BackButton("/future-residents")}
            </div>
            {isLoading ? Loading() : ''}
            {CopyFooter()}
        </div>)
    }

    return(<div>
        <div className="form">
            {Heading("Invite Resident")}
            <div className="login">
                <form onSubmit={handleSubmit}>
                    <ul>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="firstName"
                            className="text"
                            placeholder="First Name"
                            value={newResident.firstName || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="lastName"
                            className="text"
                            placeholder="Last Name"
                            value={newResident.lastName || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="emailAddress"
                            className="text"
                            placeholder="Email Address"
                            value={newResident.emailAddress || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Send Invite</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        {isLoading ? Loading() : ''}
        {CopyFooter()}
    </div>)
}
export default FutureResidentForm
