import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {postJsonNoLogin} from "./rest";
import React from 'react';

function Unsubscribe() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const [email, setEmail] = useState("")
    const [uid, setUid] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [userSettings, setUserSettings] = useState({})
    let myEmail = "";
    useEffect(()=>{
        document.title = `Manage Notification emails | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        myEmail = searchParams.get("email")
        setEmail(searchParams.get("email"))
        setUid(searchParams.get("uid"))

        if(user != null && user.email === myEmail){
            navigate("/settings")
        }
        const json = {}
        json.email = searchParams.get("email")
        json.uid = searchParams.get("uid")
        postJsonNoLogin("sign-up/email-settings", json).then((data)=>{
            if(data == null){
                alert("Sorry something has gone wrong we were unable to load your email settings")
                setIsLoading(false)
            }else{
                setUserSettings(data)
                setIsLoading(false)
            }

        })

    },[loading, user, navigate, myEmail])

    const handleCheckBoxChange = event =>{
        if(userSettings[event.target.name] === "0"){
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : "1"
            }))
        }else{
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : "0"
            }))
        }


    }

    const handleChange = event =>{
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : event.target.value
            }))
       }

       const onSubmit = async event =>{
        event.preventDefault()
           userSettings.uid = uid
           userSettings.email = email
           setIsLoading(true)
           const resp = await postJsonNoLogin("sign-up/update-email-settings", userSettings)
           alert(resp.response)
           setIsLoading(false)
       }


    return(<div>
        <div className={"form"}>
            <div className="header">
                <h2>Update Email Notifications</h2>
            </div>
            <div className={"login"}>
                <p>
                    These are your email subscription settings. You can unsubscribe from the following emails:
                </p>
                <form onSubmit={onSubmit}>
                    <ul>
                        <li>
                            <input type={"checkbox"} name={"notifyMarketplace"} value={"1"} onChange={handleCheckBoxChange} checked={userSettings.notifyMarketplace === "1"}/>
                            Marketplace Updates
                        </li>
                        <li>
                            <input type={"checkbox"} name={"notifyCommunityConnections"} value={"1"} onChange={handleCheckBoxChange} checked={userSettings.notifyCommunityConnections === "1"}/>
                            Community Connections Updates
                        </li>
                        <li>Verify Your Password</li>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                            <input
                                type="password"
                                name="password"
                                className="text"
                                placeholder="User Password"
                                autoComplete={"off"}
                                value={userSettings.password}
                                onChange={handleChange}
                            />
                        </li>
                        <li>
                            <input type="submit" name="gsvrupdate" value="Update" className="btn"/>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}
export default Unsubscribe
