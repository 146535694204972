import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import React from 'react';

import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {hasAuthority} from "./MyCookies";


function Meeting(){
    const [user, loading, error] = useAuthState(auth);
    const [media, setMedia] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [meetingAdmin, setMeetingAdmin] = useState(false)

    useEffect(()=>{
        document.title = `Meeting Minutes | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1){
            doGet("meeting/").then((data)=>{
                setMedia(data);
                setIsLoading(false);
            })
            setCount(1)
        }
        hasAuthority("ROLE_meetingMinutes").then((res)=>{
            setMeetingAdmin(res)
        })

    }, [loading, user, navigate])

    const renderBody = () => {
        return Object.keys(media).map((item, index)=>{
            return(<div>
                <h2>{item}</h2>
                {media[item].map(items=>(
                    <div className={"flex-filefolders"}><a href={`${process.env.REACT_APP_URL}/pdf/web/viewer.html?file=/files/`+items.name} target="_blank">
                        {items.title}
                    </a> </div>
                ))}
                <br/>
            </div>)
        })
    }


    return(<div>
        <div className={"form"}>
            {Heading("Meeting Minutes")}
            {isLoading ? Loading() : <div className={"login"}>
                <p>See the following documents for the minutes of these meetings:</p>
                {renderBody()}</div>}

        </div>
        {meetingAdmin ? <div className="sign logout-container flex">
            <a className={"button-icon"} href="/minutes-admin">
                <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon><br/>
                Meeting Minutes Admin
            </a>
        </div> : ""}
        {CopyFooter()}
    </div>)
}
export default Meeting
