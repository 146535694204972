import './App.css'
import {BrowserRouter, BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Login from "./Login";
import PhotoGallery from "./PhotoGallery";
import PhotoGalleryAdd from "./PhotoGalleryAdd";
import Media from "./Media";
import Weekly from "./Weekly";
import Meeting from "./Meeting";
import Files from "./Files";
import Folders from "./Folders";
import Gv from "./Gv";
import Dining from "./Dining";
import Marketplace from "./Marketplace";
import MarketplaceItem from "./MarketplaceItem";
import AddMarketplace from "./AddMarketplace";
import UserAdmin from "./UserAdmin";
import UserEdit from "./UserEdit";
import AddUserForm from "./AddUserForm";
import AddMedia from "./AddMedia";
import EditMedia from "./EditMedia";
import GVAdmin from "./GVAdmin";
import CommunityEvents from "./CommunityEvents";
import CommunityEventAdmin from "./CommunityEventAdmin";
import WeeklyAdmin from "./WeeklyAdmin";
import MeetingMinutesAdmin from "./MeetingMinutesAdmin";
import ResidentDocumentsAdmin from "./ResidentDocumentsAdmin";
import DiningAdmin from "./DiningAdmin";
import CommunityConnections from "./CommunityConnectsion";
import CommunityConnectView from "./CommunityConnectView";
import RightNow from "./RightNow";
import MicroCommunities from "./MicroCommunities";
import CatClub from "./CatClub"
import Travel from "./Trabel";
import TravelAdmin from "./TravelAdmin";
import Celebrations from "./Celebrations";
import NewResidents from "./NewResidents";
import Birthdays from "./Birthdays";
import CelebrateALife from "./CelebrateALife";
import Directory from "./Directory";
import FullResident from "./FullResident";
import PrintableDirectories from "./PrintableDirectories";
import PageNotFound from "./404";
import Settings from "./Settings";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import PWReset from "./PWReset";
import Unsubscribe from "./Unsubscribe";
import ExpiredListings from "./ExpiredListings";
import VacationForm from "./VacationForm";
import MicroCommunity from "./MicroCommunity";
import FutureResidentAdmin from "./FutureResidentAdmin";
import FutureResidentForm from "./FutureResidentForm";
import PurposefulLivingAdmin from "./PurposefulLivingAdmin";
import BannerAdmin from "./BannerAdmin";
import ViewVacations from "./ViewVacations";
import NewDashboard from "./NewDashboard";
import {useEffect} from "react";
import TouchtownSlides from "./TouchtownSlides";
function App() {

  useEffect(() => {

    document.documentElement.style.setProperty("--main-color", "#"+process.env.REACT_APP_FIREBASE_CSS_COLOR)
    document.documentElement.style.setProperty("--button-color", "#"+process.env.REACT_APP_FIREBASE_CSS_COLOR_BUTTON)
  }, []);

  return (
    <div className={'App'}>

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />}></Route>
          <Route exact path="/dashboard" element={<NewDashboard />}></Route>
          <Route exact path="/dashboard/:location" element={<NewDashboard />}></Route>
          <Route exact path="/gallery" element={<PhotoGallery />}></Route>
          <Route exact path="/gallery-add" element={<PhotoGalleryAdd />}></Route>
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/weekly-announcements" element={<Weekly />}></Route>
          <Route exact path="/minutes" element={<Meeting />}></Route>
          <Route exact path="/resident-documents" element={<Files />}></Route>
          <Route exact path="/folder/:id" element={<Folders />}></Route>
          <Route exact path="/garden-variety" element={<Gv />}></Route>
          <Route exact path="/dining-menus" element={<Dining />}></Route>
          <Route exact path="/marketplace" element={<Marketplace />}></Route>
          <Route exact path="/marketplace-item/:id" element={<MarketplaceItem />}></Route>
          <Route exact path="/marketplace-add" element={<AddMarketplace />}></Route>
          <Route exact path="/user-admin" element={<UserAdmin />}></Route>
          <Route exact path="/edit-user/:id" element={<UserEdit />}></Route>
          <Route exact path="/add-user" element={<AddUserForm />}></Route>
          <Route exact path="/add-media" element={<AddMedia />}></Route>
          <Route exact path="/edit-media/:id" element={<EditMedia />}></Route>
          <Route exact path="/add-gv" element={<GVAdmin />}></Route>
          <Route exact path="/purposeful-living" element={<CommunityEvents />}></Route>
          <Route exact path="/purposeful-living/:id" element={<CommunityEvents />}></Route>
          <Route exact path="/documents/:id" element={<CommunityEvents />}></Route>
          <Route exact path="/community-events-admin" element={<CommunityEventAdmin />}></Route>
          <Route exact path="/add-weekly" element={<WeeklyAdmin />}></Route>
          <Route exact path="/minutes-admin" element={<MeetingMinutesAdmin />}></Route>
          <Route exact path="/documents-admin" element={<ResidentDocumentsAdmin />}></Route>
          <Route exact path="/dining-admin" element={<DiningAdmin />}></Route>
          <Route exact path="/marketplace-edit/:id" element={<AddMarketplace />}></Route>
          <Route exact path="/communityconnect" element={<CommunityConnections />}></Route>
          <Route exact path="/communityconnect/:id" element={<CommunityConnectView />}></Route>
          <Route exact path="/rightnow" element={<RightNow />}></Route>
          <Route exact path="/micro-communities" element={<MicroCommunities />}></Route>
          <Route exact path="/micro-communities/cat-club" element={<CatClub />}></Route>
          <Route exact path="/travel-with-purpose" element={<Travel />}></Route>
          <Route exact path="/travel-admin" element={<TravelAdmin />}></Route>
          <Route exact path="/celebrations" element={<Celebrations />}></Route>
          <Route exact path="/celebrations/new-residents" element={<NewResidents />}></Route>
          <Route exact path="/celebrations/birthdays" element={<Birthdays />}></Route>
          <Route exact path="/celebrations/celebrate-a-life" element={<CelebrateALife />}></Route>
          <Route exact path="/directory" element={<Directory />}></Route>
          <Route exact path="/directory/full/:type" element={<FullResident />}></Route>
          <Route exact path="/directory/printable" element={<PrintableDirectories />}></Route>
          <Route exact path="/settings" element={<Settings />}></Route>
          <Route exact path="/signup" element={<SignUp />}></Route>
          <Route exact path="/signup/:type" element={<SignUp />}></Route>
          <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route exact path="/reset/:key" element={<PWReset />}></Route>
          <Route exact path="/unsubscribe" element={<Unsubscribe />}></Route>
          <Route exact path="/marketplace/expired" element={<ExpiredListings />}></Route>
          <Route exact path="/vacation-form" element={<VacationForm />}></Route>
          <Route exact path="/micro-community/:id" element={<MicroCommunity/>}></Route>
          <Route exact path="/future-residents" element={<FutureResidentAdmin/>}></Route>
          <Route exact path="/invite-future-resident" element={<FutureResidentForm/>}></Route>
          <Route exact path="/edit-future/:id" element={<FutureResidentForm/>}></Route>
          <Route exact path="/purposeful-living-admin/:id" element={<PurposefulLivingAdmin/>}></Route>
          <Route exact path="/banner-admin" element={<BannerAdmin/>}></Route>
          <Route exact path="/banner-admin/:id" element={<BannerAdmin/>}></Route>
          <Route exact path="/vacations/" element={<ViewVacations/>}></Route>
          <Route exact path="/vacations/new" element={<VacationForm/>}></Route>
          <Route exact path="/vacations/update" element={<VacationForm/>}></Route>
          <Route exact path={"/touchtown-slides"} element={<TouchtownSlides />} ></Route>
          <Route exact path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
