import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import React from 'react';
import {doGet, doPOst} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFileText, faPaperclip, faPhotoFilm, faTrashCan} from "@fortawesome/free-solid-svg-icons";

function DiningAdmin() {
    const [user, loading] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [current, setCurrent] = useState([])
    const [category, setCategory] = useState([])
    const [newMenu, setNewMenu] = useState({})
    const [selectedFile, setSelectedFile] = useState(null);
    const [newCategory, setNewCategory] = useState("")
    useEffect(()=>{
        document.title = `Admin | Dining Menus | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        hasAuthority("ROLE_diningMenus").then((res)=>{
            if(!res){
                navigate("/");
            }
        })
        if(count === 0){
            doGet("dining/all").then((res)=>{
                setCurrent(res)
                setIsLoading(false)
            })
            doGet("dining/category").then((res)=>{
                setCategory(res)
            })
            setCount(1)
        }
    },[loading, user, navigate, count])

    const onFocus = event =>{
        event.target.type = "date";

    }

    const handleChange = event =>{
        setNewMenu(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const hanleNewCat = event =>{
        setNewCategory(event.target.value)
    }

    const submitNewCat = async event =>{
        event.preventDefault()
        if(newCategory == null){
            alert("Please enter a name for the new category")
            return
        }
        setIsLoading(true)
        const formDate = new FormData();
        formDate.set("title", newCategory)
        const resp = await doPOst("dining/new-category", formDate)
        setCategory(resp)
        setNewCategory("")
        event.target.reset()
        setIsLoading(false)
        alert("Your new category has been created.")
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(newMenu.connector == null || newMenu.connector === ""){
            alert("Please select a category for the menu")
            return
        }
        if(newMenu.title == null){
            alert("Please enter a title for the menu")
            return
        }
        if(newMenu.date == null){
            alert("Please select a date for the menu")
            return
        }
        if(selectedFile == null){
            alert("Please select a file to upload")
            return
        }
        setIsLoading(true)
        const newDoc = new FormData();
        newDoc.set("title", newMenu.title)
        newDoc.set("date", newMenu.date)
        newDoc.set("connector", newMenu.connector)
        newDoc.set("file", selectedFile)
        const response = await doPOst("dining/new", newDoc)
        if(response.success){
            alert(response.response)
            setSelectedFile(null)
            setNewMenu({})
            event.target.reset()
            const update = await doGet("dining/all")
            setCurrent(update)
            setIsLoading(false)
        }


    }

    const deleteDocument = async (id) =>{
        if(window.confirm("Are you sure you want to delete this menu?")){
            setIsLoading(true)
            const response = await doGet("dining/delete/"+id)
            setCurrent(response)
            setIsLoading(false)
        }
    }

    const deleteCategory = async (id) =>{
        if(window.confirm("Are you sure you want to delete this category?  It will remove all of the menus in it as well.")){
            setIsLoading(true)
            const response = await doGet("dining/delete/folder/"+id)
            setCategory(response)
            setIsLoading(false)
        }
    }

    return (<div>
        <div className={"form"}>
            {Heading("Dining Menus Admin")}
            <div className={"login"}>
                <p>Add Menu</p>
            <form onSubmit={onSubmit}>
                <ul>
                    <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                            </span>
                        <select
                            name="connector"
                            className={"text"}
                            value={newMenu.connector}
                            onChange={handleChange}
                        >
                            <option value={""}>Select Category</option>
                            {category.map(item=>(
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="title"
                            className="text"
                            placeholder="Menu Title"
                            value={newMenu.title || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="date"
                            className="text"
                            placeholder="Publish Date"
                            value={newMenu.date || ""}
                            onChange={handleChange}
                            onFocus={onFocus}
                            required
                        />
                    </li>
                <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                    <input
                        type="file"
                        name="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        required
                    />
                </li>
                    <li>
                        <button name="gsvradd" value="Add Photo" className="btn">Add Menu</button>
                    </li>
                </ul>
            </form>
                <p>Add New Category</p>
                <form onSubmit={submitNewCat}>
                    <ul>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="newCategory"
                            className="text"
                            placeholder="Title"
                            value={newCategory || ""}
                            onChange={hanleNewCat}
                            required
                        />
                    </li>
                    <li>
                        <button name="gsvradd" value="Add Photo" className="btn">Add New Category</button>
                    </li>
                    </ul>
                </form>
                <p>Current Menus</p>
                {current.map(item=>(
                    <div className={"flex"}>
                        <div className={"text-left top-bottom-padding-large"}>
                            <div><b>{item.title}</b></div>
                            {item.diningCat != null ? <div>Folder: {item.diningCat.name}</div> : ""}
                        </div>
                        <div className={"trash-container"}><div onClick={()=> deleteDocument(item.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                    </div>
                ))}
                <p>Current Categories</p>
                {category.map(item=>(
                    <div>
                        <div className={"flex"}>
                            <div><b>{item.name}</b></div>
                            <div className={"trash-container"}><div onClick={()=>deleteCategory(item.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                        </div>
                        <br/>
                    </div>
                ))}
            </div>
        </div>
        {isLoading ? Loading() :""}
        {CopyFooter()}
    </div>)
}

export default DiningAdmin
