import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {Loading} from "./Loading";
import {doGet} from "./rest";
import {hasAuthority} from "./MyCookies";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlane} from "@fortawesome/free-solid-svg-icons";

function Travel(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [travel, setTravel] = useState({})
    const [admin, setAdmin] = useState(false)
    useEffect(()=>{
        document.title = `Travel With Purpose | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet("travel").then((data)=>{
            setTravel(data)
            setIsLoading(false)
        })
        hasAuthority("ROLE_travelWithPurpose").then((res)=>{
            setAdmin(res)
        })
    },[loading, user, navigate])

    return (<div>
        <div className={"form"}>
            {Heading("Travel With Purpose")}
            <div className={"login"}>
                {isLoading ? Loading() : <div>
                    <p>See the following travel with purpose documents & announcements:</p>
                    <br/>
                    {travel.map((val)=>(
                        <div>
                        <div><a href={`${process.env.REACT_APP_URL}/pdf/web/viewer.html?file=/files/`+val.url} target={"_blank"} rel={"noreferrer"}>{val.title}</a>
                        </div>
                            <br/>
                        </div>
                    ))}
                </div>}
            </div>

        </div>
        {admin ? <div className="sign logout-container flex">
            <a className={"button-icon"} href="/travel-admin">
                <FontAwesomeIcon icon={faPlane}></FontAwesomeIcon><br/>
                Travel Admin
            </a>
        </div> : ""}
        {CopyFooter()}
    </div>)

}
export default Travel
