import React from 'react';

function BackButton(link){

    const goBack = () =>{
        window.location.href = link;
    }

    return(
        <div className="sign back-arrow-container">
        </div>
    )
}

export default BackButton
