import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {doGet, doPOst} from "./rest";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faCalendar, faFile, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import React from 'react';


function WeeklyAdmin(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [current, setCurrent] = useState([])
    const [newWeekly, setNewWeekly] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)

    useEffect(()=>{
        document.title = `Edit Weekly | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_weekly").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        doGet("weekly/find-all").then((resp)=>{
            setCurrent(resp)
            setIsLoading(false)
        })
    })
    const submitForm = async event => {
        event.preventDefault()
        if(newWeekly.title == null){
            alert("Title is required")
            return
        }
        if(newWeekly.date == null){
            alert("Publish date is required")
            return
        }
        if(selectedFile == null){
            alert("Please select a file")
            return
        }
        setIsLoading(true)
        const postData = new FormData();
        postData.set("data", JSON.stringify(newWeekly))
        postData.set("file", selectedFile)
        const results = await doPOst("weekly/add", postData)
        if(results.success != null){
            alert(results.response)
        }else{
            alert("Weekly has been uploaded")
            setCurrent(results)
            setNewWeekly({})
        }
        setIsLoading(false)
    }

    const handleChange = event =>{
        setNewWeekly(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }
    const onFocus = event =>{
        event.target.type = "date";

    }

    const deleteItem = async(id) =>{
        if(window.confirm("Are you sure you want to delete this item?  It cannot be undone.")){
            setIsLoading(true)
            const resp = await doGet("weekly/delete/"+id)
            setCurrent(resp)
            setIsLoading(false)
        }
    }

    return(<div>
        <div className={"form"}>
            {Heading("Weekly Admin")}
            <div className={"login"}>
            <form>
                <ul>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="title"
                            className="text"
                            placeholder="Title"
                            value={newWeekly.title || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="date"
                            className="text"
                            placeholder="Publish Date"
                            value={newWeekly.date || ""}
                            onChange={handleChange}
                            onFocus={onFocus}
                            required
                        />
                    </li>
                    <li className={"flex-beside"}>
                            <span className="un">
                            <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
                            </span>
                        <input
                            type="file"
                            name="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            required
                        />
                    </li>
                    <li>
                        <button name="gsvradd" value="Add Post" className="btn" onClick={submitForm}>Add Weekly</button>
                    </li>
                </ul>
            </form>
                {current.map(item =>(
                    <div className={"flex"}>
                        <div className={"text-left top-bottom-padding-large"}>
                            {item.title}
                        </div>
                        <div className={"trash-container"} onClick={() => deleteItem(item.id)}><div><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                    </div>
                ))}
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
    </div>)



}
export default WeeklyAdmin
