import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Loading} from "./Loading";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {postJsonNoLogin} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import CopyFooter from "./CopyFooter";
import React from 'react';


function PWReset() {
    let { key } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [isValidated, setIsValidate] = useState(false)
    const [reset, setReset] = useState({})
    const resetData = {}

    useEffect(()=>{
        document.title = `Password Reset | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (user) return navigate("/dashboard");
        resetData.key = key
        postJsonNoLogin("sign-up/attempt-reset", resetData).then((resp)=>{
            if(resp.success){
                setIsValidate(true)
                reset.key = key
            }
            setIsLoading(false)
        })
    },[user, loading, navigate])

    const handleChange = event =>{
        setReset(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }
    const onSubmit = async event =>{
        event.preventDefault()
        if(reset.password.length < 6){
            alert("Password Must be at least 6 characters long.")
            return
        }
        setIsLoading(true)
        const resp = await postJsonNoLogin("sign-up/reset-password", reset)
        alert(resp.response)
        if(resp.success){
            navigate("/")
        }
        setIsLoading(false)
    }


    return(<div>
        <div className={"form"}>
            <div className="header-login">
                <h2>Password Reset</h2>
            </div>
            <div className={"login"}>
                {isLoading ? Loading() : isValidated ? <div>
                    <p>Please type in a new password below.  It must be at least 6 characters long.</p>
                    <form onSubmit={onSubmit}>
                        <ul>
                            <li className={"flex"}>
                        <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="password"
                                    name="password"
                                    className="text"
                                    placeholder="Enter New Password"
                                    value={reset.password || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li>
                                <input type="submit" name="gsvrupdate" value="Continue" className="btn"/>
                            </li>
                        </ul>
                    </form>
                </div>:<div>
                    <br/><br/>
                    We were unable to validate your password reset.  Please try again.
                </div>}
            </div>
        </div>
        {CopyFooter()}
    </div>)
}
export default PWReset
