import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {useEffect, useState} from "react";
import {Loading} from "./Loading";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, getUid} from "./firebase";
import {useNavigate} from "react-router-dom";
import {doGet, doPOst} from "./rest";
import {faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';


function CommunityConnections() {
    const [user, loading] = useAuthState(auth);
    const [interests, setInterests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [newInterest, setNewInterest] = useState("")
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `Community Connections | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet("community-connections/").then((data)=>{
            setInterests(data)
            setIsLoading(false)
        })
    }, [loading, user, navigate])

    const joinGroup = async (grpid) =>{
        setIsLoading(true)
        const response = await doGet("community-connections/group/join/"+grpid)
        setInterests(response)
        setIsLoading(false)
    }

    const leaveGroup = async (grpid) =>{
        setIsLoading(true)
        const response = await doGet("community-connections/group/leave/"+grpid)
        setInterests(response)
        setIsLoading(false)
    }

    const inCurrentGroup = (group, grpid, createdId) =>{
        if(createdId === getUid()){
            return ""
        }
        for(const [_, value] of group.entries()) {
            if (value.users != null && value.users.uid === getUid()) {
                return <span id={grpid}><a href='#'  onClick={()=>leaveGroup(grpid)}>Leave Group</a></span>
            }
        }

        return <span id={grpid}><a href='#' onClick={()=>joinGroup(grpid)}>Join Group</a></span>
    }

    const setInterestData = event =>{
        setNewInterest(event.target.value)
    }

    const addNewInterest = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const formData = new FormData();
        formData.set("new", newInterest)
        const response = await doPOst("community-connections/new", formData)
        alert(response.response)
        if(response.success){
            const newDate = await doGet("community-connections/")
            setInterests(newDate)
            setNewInterest("")
            event.target.reset()
        }
        setIsLoading(false)
    }

    return(<div>
        <div className={"form"}>
            {Heading("Community Connections")}
            <div className={"login"}>
                <p>Join a group your interested in. Or create a new group and find others with that same interest!</p>
                {interests.map((item)=>(
                    <div><a href={"/communityconnect/"+item.uid}>{item.name}</a>
                    <br/>
                        <small>({item.connections.length} Members) {inCurrentGroup(item.connections, item.uid, item.createdId)}</small>
                        <br/><br/>
                    </div>
                ))}
                <br/><br/>
                <form onSubmit={addNewInterest}>
                    <ul>
                        <li className={"flex"}>
                        <span className="un"><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon></span>
                        <input type="text" name="newInterest" className="text" value={newInterest} placeholder="Your Interest"
                               onChange={setInterestData}
                               maxLength="100"/>
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Post" className="btn" >Add Interest</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}

export default CommunityConnections
