import {authToken} from "./firebase";

//const url = "http://localhost:8080/"
const url = process.env.REACT_APP_API_ENDPOINT

const vacationUrl = "https://mkdir.gsc.app/mygsv/"

const doGet = async (location) =>{

    let header = await authToken();

    let response = await fetch(url+location,{
        headers: new Headers({"Authorization" : "jwt "+header})
    });
        let text = await response.text();

    return text.length ? JSON.parse(text) : {}
}

const doGetString = async (location) =>{

    let header = await authToken();

    let response = await fetch(url+location,{
        headers: new Headers({"Authorization" : "jwt "+header})
    });
    let text = await response.text();

    return text.length ? text : ''
}

const doGetVacation = async (location)=>{
    let header = await authToken();

    let response = await fetch(vacationUrl+location,{
        headers: new Headers({"Authorization" : "jwt "+header})
    });
    let text = await response.text();

    return text.length ? JSON.parse(text) : {}
}

const doGetText = async (location) =>{
    let header = await authToken();

    let response = await fetch(url+location,{
        headers: new Headers({"Authorization" : "jwt "+header})
    });
    let text = await response.text();

    return text;
}
const getBlob = async (location) =>{
    let header = await authToken();

    let response = await fetch(url+location,{
        headers: new Headers({"Authorization" : "jwt "+header})
    });
    return response.blob()
}

const doPOst = async (location, formData) =>{
    let header = await authToken();
    const options = {
        method: 'POST',
        body: formData,
        headers: new Headers({"Authorization" : "jwt "+header})
    }


    let response = await fetch(url+location, options);


    return await response.json()

}

const doPostJsonVacation = async (location, data)=>{
    let header = await authToken();
    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({"Authorization" : "jwt "+header, "Content-Type": "application/json"})
    }
    let response = await fetch(vacationUrl+location, options);


    return await response.json()
}

const postJson = async (location, data) =>{
    let header = await authToken();
    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({"Authorization" : "jwt "+header, "Content-Type": "application/json"})
    }
    let response = await fetch(url+location, options);


    return await response.json()
}

const postJsonNoLogin = async (location, data) =>{
    const options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"})
    }
    let response = await fetch(url+location, options);
    return await response.json()
}

export {
    doGet,
    doGetString,
    doPOst,
    postJson,
    getBlob,
    doGetText,
    postJsonNoLogin,
    doGetVacation,
    doPostJsonVacation
};
