import {useAuthState} from "react-firebase-hooks/auth";
import {auth, authToken} from "./firebase";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Heading from "./Heading";
import {doGet} from "./rest";
import {Client} from "@stomp/stompjs";
import {clearCookies, hasAuthority} from "./MyCookies";
import {Loading} from "./Loading";
import ImageLoader from "./ImageLoader";
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faMessage} from "@fortawesome/free-solid-svg-icons/faMessage";
import usePackageTracking from "./usePackageTracking";




function NewDashboard() {
    let { location } = useParams();
    const nav = useLocation()
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [userAdmin, setUserAdmin] = useState(false);
    const [isResident, setIsResident] = useState(false)
    const [banner, setBanner] = useState({})
    const [messageAdmin, setMessageAdmin] = useState(false)
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    let client;
    useEffect(()=>{
        document.title = `Dashboard | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        setListener()
    },[isLoading, loading, location, userAdmin, isResident])

    useEffect(()=>{
        if (loading) return;
        startSocket()
    }, [loading])

    usePackageTracking()

    function startSocket(){
        //TODO: this stupid thing just keeps fireing, need to figure this out
        authToken().then((res)=>{
            client = new Client({
                brokerURL: process.env.REACT_APP_WS,
                connectHeaders: {
                    Authorization: 'jwt ' + res
                },
                onConnect: () => {
                    if (isResident) {
                        client.subscribe("/topic/message", message => {
                            setBanner(JSON.parse(message.body))
                        })
                    }
                    client.subscribe("/user/" + user.uid + "/msg", message => {
                        try {
                            const data = JSON.parse(message.body)
                            if (data.isResident != null) {
                                clearCookies()
                                setIsResident(data.isResident)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    })
                    setInterval(() => {
                        client.publish({destination: '/app/ping', body: 'ping'})
                    }, 90000)
                }
            })
            client.activate()
        })
    }


    function setListener(){

        checkUserAdmin().then(()=>{

            if(location == null){
                location = "/";
            }else{
                location = `/${location.toLowerCase()}`
            }
            doGet("repositories?location="+location+"&isResident="+isResident+"&appVersion=20240513").then((res)=>{
                setItems(res)
                setIsLoading(false)
            })
            if(isResident){
                doGet("top-messaging").then((res)=>{
                    if(res != null){
                        setBanner(res)
                    }
                });
            }

        })
    }

    const checkUserAdmin = async () =>{
        const userRole = await hasAuthority("ROLE_users")
        setUserAdmin(userRole)
        const resident = await hasAuthority("ROLE_RESIDENT")
        setIsResident(resident)
        const messaging = await hasAuthority("ROLE_bannerAdmin")
        setMessageAdmin(messaging)
    }

    const onItemClick = (item) =>{
        switch (item.type) {
            case "APP_LINK":
                if(item.route === "/restaurant-reviews"){
                    window.open("https://www.gardenspotvillage.org/village-life/local-attractions/restaurants/", '_blank')
                    break
                }
                if(item.route === "/lands-end-storefront"){
                    window.open("https://business.landsend.com/store/gardenspotcommunities/", '_blank')
                    break
                }
                navigate(item.route)
                break
            case "DOCUMENTS":
                navigate(`/documents/${item.documentRepositoryLink}`, {state: item.title})
                break

            case "URL":
                window.open(item.route, '_blank')
                break

            case "REPOSITORY":
                const data = {"location": item.route, "isResident": isResident, "title": item.title};
                navigate(`/dashboard/${item.title.replaceAll(" ", "-").toLowerCase()}`, {state: data})
                break

        }
    }

    return(<div>
        <div className="form">
            {banner.message != null && isResident ? <div dangerouslySetInnerHTML={{__html: banner.message}}></div> : '' }
            {Heading(process.env.REACT_APP_TITLE)}
            <div id="content" className="padding-content">
                <div className={"login"}>
                    <div className="flex-wrap">
            {isLoading ? Loading() :<>
                {items.map(item=>(
                        <div className="button-container">
                            <a onClick={()=>onItemClick(item)}>
                                {ImageLoader(item['icon'])}
                            </a>
                        </div>
                ))}</>
            }
                    </div>
                </div>
            </div>

        </div>
        {(messageAdmin || userAdmin) ? <div className="sign logout-container flex-space-between">
            {userAdmin ?     <a className={"button-icon"} href="/user-admin">
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><br/>
                User Admin
            </a>: ""}
            {messageAdmin ? <a className={"button-icon"} href="/banner-admin">
                <FontAwesomeIcon icon={faMessage}></FontAwesomeIcon><br/>
                Banner Admin
            </a>: "" }
        </div> :<></>}
        {CopyFooter()}
    </div>)


}

export default NewDashboard
