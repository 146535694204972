import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, getUid} from "./firebase";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import React from 'react';
import CopyFooter from "./CopyFooter";


function MarketplaceItem(){
    let { id } = useParams();
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [canedit, setCanedit] = useState(false)

    useEffect(()=>{
        document.title = `Marketplace | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1){
            doGet("marketplace/item/"+id).then((res)=>{
                setData(res)
                if(res["item"].link === getUid()){
                    setCanedit(true)
                }
                setIsLoading(false)

            })
            setCount(1)
        }
    }, [loading, user, navigate])

    return(<div>
        <div className={"form"}>
            {Heading("Marketplace Item")}
            <div id="content" className="padding-content">
                {isLoading ? Loading() : <div>
                    <div className={"login"}>
                        <p>View the selected item below for item information and to contact the owner.</p>
                        <br/><br/>
                        <img src={`${process.env.REACT_APP_URL}/uploads/marketplace/full/`+data["item"].pimage} alt={"img"} width={"100%"}/>
                        <b>{data["item"].ptitle}</b>
                        <div className={"text-left"}>Posted by: <a href={"mailto:"+data["seller"].email}>{data["seller"].fname} {data["seller"].lname}</a> {data["seller"].phone}
                        <br/><br/><br/>
                            <b>Price: </b>{data["item"].pprice}
                            <br/><br/><br/>
                            <b>Description:</b><br/>
                            {data["item"].pdescription}
                        </div>
                        {canedit ? <div>
                            <br/><br/>
                            <a href={"/marketplace-edit/"+data["item"].uid}> [Edit My Posting]</a></div> : ""}
                    </div>
                </div>}
            </div>
        </div>
        {CopyFooter()}
    </div>)

}
export default MarketplaceItem
