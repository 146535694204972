import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Heading from "./Heading";
import {faArrowCircleLeft, faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {doPOst} from "./rest";
import Player from "./Player";
import {residentNameFormatterFirstNameLastName} from "./displayNameGenerator";

function NewResidents() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [month, setMonth] = useState("")
    const [year, setYear] = useState()
    const [people, setPeople] = useState([])
    const [monthNumber, setMonthNumber] = useState(0)
    const [isOpen, setIsOpen] = useState(false);
    const [popupdisplay, setPopupDisplay] = useState(null)
    useEffect(()=>{
        document.title = `New Residents | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        const date = new Date();
        setMonth(monthNames[date.getMonth()])
        setMonthNumber(date.getMonth())
        setYear(date.getFullYear())
        doPOst("celebrations/new-move-ins-new").then((data)=>{
            console.log(data)
            setPeople(data)
            setIsLoading(false)
        })
    },[loading, user, navigate])

    const getPhotoFull = (person)=>{
        if(person.homePhoto != null && person.homePhoto !== "" && person.residentsNew.length > 1){
            return <img alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.homePhoto} width={500}/>
        }
        if(person.residentsNew.length > 1){
            return  <img src="https://gsv.gsrdp.org/images/photo-placeholder-couple-thumb.jpg" width={500}/>
        }
        if(person.residentsNew[0].photo !== null && person.residentsNew[0].photo !== ""){
            return <img alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.residentsNew[0].photo} width={500}/>
        }
        if(person.residentsNew[0].residentGender === "female"){
            return <img src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width={500}/>
        }
        return <img src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={500}/>
    }
    const getPhoto = (person)=>{
        if(person.homePhoto != null && person.homePhoto !== ""){
            return <img alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.homePhoto} width={"175"}/>
        }
        if(person.residentsNew.length > 1){
            return <img src="https://gsv.gsrdp.org/images/photo-placeholder-couple-thumb.jpg" alt={"photo"} width={"175"}/>
        }
        if(person.residentsNew[0].residentGender === "female"){
            return <img src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" alt={"photo"} width={"175"}/>
        }
        return <img src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"175"}/>
    }
    const displayName = (person) =>{
        const reversed = [...person.residentsNew].reverse()
        return residentNameFormatterFirstNameLastName(reversed)
    }
    const togglePopup = (data) => {
        setPopupDisplay(data);
        setIsOpen(!isOpen);
    }

    const changeDateForward = async event =>{
        event.preventDefault()
        const today = new Date()
        const year1 = today.getFullYear()
        const month1 = today.getMonth();
        if(month !== monthNames[month1] || year1 !== year){
            setIsLoading(true)
            const dateChange = new Date(year, monthNumber, 1)
            const newDate = new Date(dateChange.setMonth(dateChange.getMonth()+1))
            const formDate = new FormData()
            const displayMont = newDate.getMonth()+1
            formDate.set("date", newDate.getFullYear()+"-"+("0"+displayMont).slice(-2)+"-01")
            const resp = await doPOst("celebrations/new-move-ins-new", formDate)
            setMonthNumber(newDate.getMonth())
            setYear(newDate.getFullYear())
            setMonth(monthNames[newDate.getMonth()])
            setPeople(resp)
            setIsLoading(false)
        }
    }
    const changeDateBack = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const dateChange = new Date(year, monthNumber, 1)
        const newDate = new Date(dateChange.setMonth(dateChange.getMonth()-1))
        const formDate = new FormData()
        const displayMont = newDate.getMonth()+1
        formDate.set("date", newDate.getFullYear()+"-"+("0"+displayMont).slice(-2)+"-01")
        const resp = await doPOst("celebrations/new-move-ins-new", formDate)
        setMonthNumber(newDate.getMonth())
        setYear(newDate.getFullYear())
        setMonth(monthNames[newDate.getMonth()])
        setPeople(resp)
        setIsLoading(false)
    }

    return(<div>
        <div className={"form"}>
            {Heading("New Residents")}
            <div className={"padding-content"}>
            <div className={"login"}>
                <h3>
                    <a id={"back"} onClick={changeDateBack} href="#"><FontAwesomeIcon icon={faArrowCircleLeft}></FontAwesomeIcon></a>
                    {month} {year}
                    <a id={"forward"} onClick={changeDateForward} href="#"><FontAwesomeIcon icon={faArrowCircleRight}></FontAwesomeIcon></a>
                </h3>

                <br/>
                <div className={"flex-photos"}>
                    {people.map(person=>(<div className={"gallery-item cursor-pointer"} onClick={()=>togglePopup(person)}>
                        {getPhoto(person)}
                        {displayName(person)}<br/>
                        {person.neighborhood.neighborHoodCommunityName}
                    </div>))}
                </div>
            </div>
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
        {isOpen && <Player
            content={<>
                 <div>
                    {getPhotoFull(popupdisplay)}<br/>
                    <div className={"text-left black-text"}>
                        <b>Name: </b>{displayName(popupdisplay)}
                        <div><b>Address: </b>{popupdisplay.homeNumber} {popupdisplay.address}</div>
                        {(popupdisplay.homePhone != null && popupdisplay.homePhone !== "")? <div>
                            <b>Home Phone: </b>{popupdisplay.homePhone}
                        </div>:""}
                        {(popupdisplay.homeEmail != null && popupdisplay.homeEmail !== "")? <div>
                            <b>Home Email: </b>{popupdisplay.homeEmail}
                        </div>:""}
                        {popupdisplay.residentsNew.map(res=>(
                            (res.cellNumber !== null && res.cellNumber !== "")?
                                <div>
                                    <b>{res.firstName}'s Cell Phone: </b>{res.cellNumber}
                                </div>:""
                        ))}
                        {popupdisplay.residentsNew.map(res=>(
                            (res.emailAddress !== null && res.emailAddress !== "")?
                                <div>
                                    <b>{res.firstName}'s Email Address: </b>{res.emailAddress}
                                </div>:""
                        ))}
                    </div>
                </div>
            </>}
            handleClose={togglePopup}
        ></Player>}
    </div>)
}
export default NewResidents
