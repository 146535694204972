import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {doGet, doPOst, doPostJsonVacation} from "./rest";
import Player from "./Player";
import {residentNameFormatterFirstNameLastName} from "./displayNameGenerator";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

function Directory() {
    const [user, loading] = useAuthState(auth);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState("all")
    const [results, setResults] = useState({})
    const [popupdisplay, setPopupDisplay] = useState(null)
    const [popupType, setPopuptype] = useState(null)
    const [departments, setDepartments] = useState([])
    const [communities, setCommunities] = useState([])
    const [departmenSelections, setDepartmentSelections] = useState([])
    const [selectedCommunityStrings, setSelectedCommunityString] = useState([])
    const [selectedDepartmentString, setSelectedDepartmentString] = useState([])
    const [multiSearch, setMultiSearch] = useState(false)
    const animatedComponents = makeAnimated();
    const [residentCommunities, setResidentCommunities] = useState([])
    const [residentStreets, setResidentStreets] = useState([])
    const [selectedResidentCommunityString, setSelectedResidentCommunityStrings] = useState([])
    let dept = null;
    useEffect(()=>{
        document.title = `Directory | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        loadDepartments()
        loadCommunities()
        loadResidentCommunities()
    },[loading, user, navigate])

    const loadResidentCommunities = async() =>{
        const response = await doGet("celebrations/directory/distinct-location")
        const myArray = []
        for(const item in response){
            myArray.push({'value':response[item], 'label':response[item]})
        }
        setResidentCommunities(myArray)
    }

    const loadDepartments = async () =>{
        const response = await doGet("celebrations/departments")
        setDepartments(response)

    }

    const loadCommunities = async () =>{
        const response = await doGet("celebrations/communities")
        const myarray = []
        for(const item in response){
            myarray.push({'value':response[item].name, 'label':response[item].name})
        }
        setCommunities(myarray)
    }

    const handleChange = event =>(
        setSearch(event.target.value)
    )

    const submitForm = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const formDate = new FormData()
        formDate.set("search", search)
        const results = await doPOst("celebrations/directory", formDate)
        setResults(results)
        setIsLoading(false)
    }
    const displayName = (person) =>{
        const reversed = [...person.residentsNew].reverse()
        return residentNameFormatterFirstNameLastName(reversed)
    }

    const selectionChange = event =>{
        if(multiSearch){
            setMultiSearch(false)
            setSelectedDepartmentString([])
            setSelectedCommunityString([])
            setDepartmentSelections([])
            setResults({})
        }
        setFilter(event.target.value)
    }
    const getPhoto = (person)=>{
        if(person.homePhoto != null && person.homePhoto !== "" && person.residentsNew.length > 1){
            return <img loading="lazy" alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.homePhoto} width={"175"}/>
        }
        if(person.residentsNew.length > 1){
           return  <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-couple-thumb.jpg" width="175"/>
        }
        if(person.residentsNew[0].photo !== null && person.residentsNew[0].photo !== ""){
            return <img loading="lazy" alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.residentsNew[0].photo} width={"175"}/>
        }
        if(person.residentsNew[0].residentGender === "female"){
            return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width="175"/>
        }
        return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"175"}/>
    }

    const getPhotoFull = (person)=>{
        if(person.homePhoto != null && person.homePhoto !== "" && person.residentsNew.length > 1){
            return <img loading="lazy" alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.homePhoto} width={500}/>
        }
        if(person.residentsNew.length > 1){
            return  <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-couple-thumb.jpg" width={500}/>
        }
        if(person.residentsNew[0].photo !== null && person.residentsNew[0].photo !== ""){
            return <img loading="lazy" alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.residentsNew[0].photo} width={500}/>
        }
        if(person.residentsNew[0].residentGender === "female"){
            return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width={500}/>
        }
        return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={500}/>
    }

    const getPhotoEmployee = (person) =>{
        if(person.photoFileName !== null && person.photoFileName !== "") {
            return <img loading="lazy" src={"https://photos.team.gsc.gl/" + person.uid + "/" + person.photoFileName} width="175"/>
        }
        if(person.gender === "female"){
            return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width="175"/>
        }
        return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"175"}/>
    }
    const getPhotoEmployeeFull = (person) =>{
        if(person.photoFileName !== null && person.photoFileName !== "") {
            return <img loading="lazy" src={"https://photos.team.gsc.gl/" + person.uid + "/" + person.photoFileName} width="500"/>
        }
        if(person.gener === "female"){
            return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" width="500"/>
        }
        return <img loading="lazy" src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"500"}/>
    }
    const togglePopup = (data, type) => {
        setPopupDisplay(data)
        setPopuptype(type)
        setIsOpen(!isOpen);
    }
    const onMultiSelectChange = async (val) =>{
        const newSelections = []
        const alreadyHasTest = []
        const selectedCommunities1 = []
        for(const place in val){
            selectedCommunities1.push(val[place].value)
            for(const item in departments){
                if(departments[item].community.name === val[place].value && !alreadyHasTest.includes(departments[item].name)){
                    alreadyHasTest.push(departments[item].name)
                    newSelections.push({'label': departments[item].name, 'value':departments[item].name})
                }
            }
        }
        setDepartmentSelections(newSelections)
        setSelectedCommunityString(selectedCommunities1)
        if(selectedCommunities1.length === 0 && search.length === 0){
            setResults({})
            return;
        }
        if(results.team === undefined || results.team.length === 0 && selectedCommunities1.length > 0){
            const formDate = new FormData()
            formDate.set("search", search)
            formDate.set("employee-params", selectedCommunityStrings.join(","));
            const results = await doPOst("celebrations/directory", formDate)
            setMultiSearch(true)
            setResults(results)
        }
    }

    const onMultiSelectDepartmentChange = (val) =>{
        const selected = []
        for(const item in val){
            selected.push(val[item].value)
        }
        setSelectedDepartmentString(selected)
    }

    const onResidentCommunitySelect = async (val) =>{
        const selected = []
        setMultiSearch(true)
        for(const item in val){
            selected.push(val[item].value)
        }
        setSelectedResidentCommunityStrings(selected)
        const formData = new FormData()
        formData.set("locations", selected.join(","))
        const otherSelect = await doPOst("celebrations/directory/distinct-streets", formData)
        if(search != null && search.length > 0){
            formData.set("search", search)
        }
        const resp = await doPOst("celebrations/directory/search-by-location", formData)
        const streets = []
        for(const item in otherSelect){
            streets.push({'value': otherSelect[item], 'label':otherSelect[item]})
        }
        setResidentStreets(streets)
        setResults({...{"residents" : resp}})
    }

    const onResidentStreetsSelect = async (val) => {
        const formData = new FormData()
        const selected = []
        for(const item in val){
            selected.push(val[item].value)
        }
        formData.set('locations', selectedResidentCommunityString.join(","))
        formData.set("streets", selected.join(","))
        if(search != null && search.length > 0){
            formData.set("search", search)
        }
        const resp = await doPOst("celebrations/directory/search-by-location-and-street", formData)
        setResults({...{"residents" : resp}})
    }


    const displayPerson = (person) => {
        if(selectedCommunityStrings.length > 0){
            if(!selectedCommunityStrings.includes(person.community.toString().trim())){
                return ''
            }
            if(selectedDepartmentString.length > 0){
                if(!selectedDepartmentString.includes(person.teamDepartments.name.toString().trim())){
                    return ''
                }
            }
        }


        return (<a href={"javascript:void(0)"} onClick={() => togglePopup(person, "tm")}>
            <div className={"gallery-item text-left"}>
                {getPhotoEmployee(person)}
                {person.fname} {person.lname}<br/>
                {person.community}<br/>
                <small>{person.teamDepartments.name}</small><br/>
                <small>{person.position}</small>
            </div>
        </a>)
    }


    return (<div>
        <div className={"form"}>
            {Heading("Directory")}
            <div className={"login"}>
                <form onSubmit={submitForm}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="search"
                                className="text"
                                placeholder="Search"
                                value={search || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li>
                            Filter By:<br/><br/>
                            <div className={"flex"}>
<div>
                            <input type={"radio"} name={"search_type"} onChange={selectionChange} value={"all"} checked={filter==="all"}/>
                            All
</div><div>
                            <input type={"radio"} name={"search_type"} onChange={selectionChange} value={"resident"} checked={filter==="resident"}/>
                            Resident
                            </div><div>
                            <input type={"radio"} name={"search_type"} onChange={selectionChange} value={"team"} checked={filter==="team"}/>
                            Team Member
                            </div><div>
                            <input type={"radio"} name={"search_type"} onChange={selectionChange} value={"campus"} checked={filter==="campus"}/>
                            Campus
                            </div>
                            </div>

                        </li>
                        {filter === "team" ?
                            <Select
                                placeholder={'Select Community'}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={onMultiSelectChange}
                                options={communities}
                            /> : ""}
                        {filter === "team" && departmenSelections.length > 0 ?
                           <><br/> <Select
                               placeholder={'Select Department'}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={onMultiSelectDepartmentChange}
                                options={departmenSelections}
                            /> </>: ''}
                        {filter === "resident" ? <>
                            <Select
                                placeholder={'Select Community'}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={onResidentCommunitySelect}
                                options={residentCommunities}
                            />
                        </> : <></>}

                        {filter === "resident" && residentStreets.length > 0 ? <>
                            <br/>
                            <Select
                                placeholder={'Select Location'}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                onChange={onResidentStreetsSelect}
                                options={residentStreets}
                            />
                        </> : <></>}
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Search</button>
                        </li>
                    </ul>
                </form>

                {(results.residents != null && (filter === "all" || filter === "resident") )? <div id={"residents"}>
                    <p>Residents</p>
                        <div className={"padding-content"}>
                            <div className={"flex-photos"}>
                            {results.residents.map(person =>(
                               <a href={"javascript:void(0)"} onClick={()=> togglePopup(person, "resident")}> <div className={"gallery-item text-left"}>
                                    {getPhoto(person)}
                                    {displayName(person)}<br/>
                                    {person.homeNumber} {person.address}<br/>
                                   {person.neighborhood.neighborHoodCommunityName}
                               </div></a>
                            ))}
                            </div>
                        </div>
                </div>:""}
                {(results.team != null  && (filter === "all" || filter === "team") )? <div id={"team"}>
                    <p>Team Members</p>
                    <div className={"padding-content"}>
                    <div className={"flex-photos"}>
                        {results.team.map(person=>(
                            displayPerson(person)
                        ))}
                    </div>
                    </div>
                </div>:""}
                {(results.campus != null && (filter === "all" || filter === "campus")) ? <div id={"campus"}>
                    <p>Campus Directory</p>
                        <div className={"padding-content"}>
                            <div className={"flex-photos"}>
                                {results.campus.map(camp=>(
                                    <div className={"gallery-item text-left"}>
                                        <b>{camp.label}</b>
                                        <br/>
                                        {camp.campusCategories.name}
                                        <br/>
                                        {camp.number}
                                    </div>
                                ))}
                            </div>
                        </div>
                </div>:""}
                <a href={"/directory/printable"}>View Full and Printable Directories</a>
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
        {isOpen && <Player
            content={<>
            {popupType === "resident" ? <div>
                {getPhotoFull(popupdisplay)}<br/>
                <div className={"text-left black-text"}>
                <b>Name: </b>{displayName(popupdisplay)}
                    <div><b>Address: </b>{popupdisplay.homeNumber} {popupdisplay.address}</div>
                    {(popupdisplay.homePhone != null && popupdisplay.homePhone !== "")? <div>
                        <b>Home Phone: </b>{popupdisplay.homePhone}
                    </div>:""}
                    {(popupdisplay.homeEmail != null && popupdisplay.homeEmail !== "")? <div>
                        <b>Home Email: </b>{popupdisplay.homeEmail}
                    </div>:""}
                    {popupdisplay.residentsNew.map(res=>(
                        (res.cellNumber !== null && res.cellNumber !== "")?
                            <div>
                                <b>{res.firstName}'s Cell Phone: </b>{res.cellNumber}
                            </div>:""
                    ))}
                    {popupdisplay.residentsNew.map(res=>(
                        (res.emailAddress !== null && res.emailAddress !== "")?
                            <div>
                                <b>{res.firstName}'s Email Address: </b>{res.emailAddress}
                            </div>:""
                    ))}
                </div>
            </div> : ""}
            {popupType === "tm" ?<div>
                {getPhotoEmployeeFull(popupdisplay)}
                <div className={"text-left black-text"}>
                    <b>Name: </b>{popupdisplay.fname} {popupdisplay.lname}
                    <div>{popupdisplay.community}</div>
                    <div><b>Department: </b>{popupdisplay.teamDepartments.name}</div>
                    <div><b>Position: </b>{popupdisplay.position}</div>
                    {(popupdisplay.phone != null && popupdisplay.phone !== "") ?<div>
                        <b>Phone: </b>{popupdisplay.phone} {popupdisplay.extension != null && " Ext."+popupdisplay.extension}
                    </div>:""}
                    {(popupdisplay.email != null && popupdisplay.email !== "") ?<div>
                        <b>Email: </b>{popupdisplay.email}
                    </div>:""}
                    {(popupdisplay.mobile != null && popupdisplay.mobile !== "") ?<div>
                        <b>Cell: </b>{popupdisplay.mobile}
                    </div>:""}
                </div>
            </div>:""}
                        </>}
            handleClose={togglePopup}
        ></Player>}
    </div>)
}
export default Directory
