import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import React from 'react';
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "./Loading";
import {doGet} from "./rest";

function CommunityEvents() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [ceAdmin, setCeAdmin] = useState(false)
    const [docs, setDocs] = useState([])
    const [items, setItems] = useState({})
    useEffect(()=>{
        document.title = `Purposeful Living | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        hasAuthority("ROLE_communityEvents").then((res)=>{
            setCeAdmin(res)
        })
        if(count === 0){
            if(id == null) {
                doGet("purposeful-living").then((res) => {
                    setDocs(res)
                    setIsLoading(false)
                })
            }else{
                doGet("purposeful-living/"+id).then((res) => {
                    setItems(res)
                    setIsLoading(false)
                })
            }
            setCount(1)
        }
    }, [loading, user, navigate])

    const onClick = (location) =>{
        window.open(process.env.REACT_APP_URL+"/pdf/web/viewer.html?file=/files/"+location, ".blank")
    }

    const renderBody = () =>{
        return docs.map(items =>(
            <div className={"button-container"}>
                <a href={"/purposeful-living/"+items.id}>
                    <img className={'new-button-size'} src={`data:image/png;base64,${items.icon}`}/>
                </a>
            </div>
        ))
       // return Object.keys(docs).map((item, index) => {
        //    return (<div>
        //        <h2>{item}</h2>
       //         {docs[item].map(items=>(
         //           <div className={"flex-filefolders"}><a href="javascript:void(0)" onClick={()=> onClick(items.fileName)}>{items.title}</a></div>
         //       ))}
         //       <br/>
         //   </div>);
       // })
    }

    const renderItems = () =>{
        return items.categories.map(item =>(
            item.documents.length > 0 ?
            <div>
                <h2>{item.categoryName}</h2>
                {item.documents.map(doc =>(
                    <div className={"flex-filefolders"}><a href="javascript:void(0)" onClick={()=>onClick(doc.documentName)}>{doc.documentTitle}</a> </div>
                ))}
            </div> :''
        ))
    }

    if(id != null){
        return(<div>
            <div className={"form"}>
                {Heading(items.title ?? '')}
                <div className={"login"}>
                    {items.categories != null ? renderItems(): ''}
                </div>

            </div>
            {ceAdmin ? <div className="sign logout-container flex">
                <a className={"button-icon"} href={"/purposeful-living-admin/"+items.id}>
                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon><br/>
                    Manage This Category
                </a>
            </div> : ""}
            {isLoading ? Loading() : ""}
            {CopyFooter()}
        </div>)
    }

    return(<div>
        <div className={"form"}>
            {Heading("Purposeful Living")}
            <div className={"login"}>
                <div className={"flex"}>
                {renderBody()}
                </div>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}
export default CommunityEvents
