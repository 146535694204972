import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {doGet, doPOst, postJson} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileText, faPaperclip, faPhotoFilm, faTrashCan} from "@fortawesome/free-solid-svg-icons";

function ResidentDocumentsAdmin() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([])
    const [folders, setFolders] = useState([])
    const [newFile, setNewFile] = useState({})
    const [selectedFile, setSelectedFile] = useState(null);
    const [newFolder, setNewFolder] = useState({})
    useEffect(()=>{
        document.title = `Admin | Resident Documents | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        hasAuthority("ROLE_files").then((res)=>{
            if(!res){
                navigate("/");
            }
        })
        if(count === 0){
            doGet("files/all").then((items)=>{
                setFiles(items)
            })
            doGet("files/folders").then((itesm)=>{
                setFolders(itesm)
                setIsLoading(false)
            })
            setCount(1)
        }
    },[user, count, loading, navigate])
    const handleChange = event =>{
        setNewFile(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleChangeFolder = event =>{
        setNewFolder(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const onSubmitFolder = async event =>{
        event.preventDefault()
        if(newFolder.title == null){
            alert("Folder Title is required")
            return
        }
        setIsLoading(true)
        const response = await postJson("files/new-folder", newFolder)
        setFolders(response)
        alert("Your new folder has been added")
        setNewFolder({})
        event.target.reset()
        setIsLoading(false)
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(newFile.title == null){
            alert("Document title is required")
            return
        }
        if(selectedFile == null){
            alert("Please select a file to upload")
            return
        }
        setIsLoading(true)
        const newDoc = new FormData();
        newDoc.set("title", newFile.title)
        newDoc.set("folderUid", newFile.folderUid ?? "")
        newDoc.set("file", selectedFile)
        const resp = await doPOst("files/new-doc", newDoc)
        if(resp.success){
            const docs = await doGet("files/all")
            setFiles(docs)
            setNewFile({})
            setSelectedFile(null)
            event.target.reset()
            setIsLoading(false)
            alert(resp.response)
        }else{
            setIsLoading(false)
            alert(resp.response)
        }
    }
    const deleteFile = async (id) =>{
        if(window.confirm("Are you sure you want to delete this file?")){
            setIsLoading(true)
            const result = await doGet("files/delete/"+id)
            setFiles(result)
            setIsLoading(false)
        }
    }

    const deleteFolder = async (id) =>{
        if(window.confirm("Are you sure you want to delete this folder?  You will also loose all of the containing files.")){
            setIsLoading(true)
            const response = await doGet("files/folder/delete/"+id)
            setFolders(response)
            setIsLoading(false)
        }
    }


    return(<div>
        <div className={"form"}>
            {Heading("Resident Documents Admin")}
            <div className={"login"}>
                <p>Add Document</p>
                <form onSubmit={onSubmit}>
                    <ul>
                    <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                        <input
                            type="text"
                            name="title"
                            className="text"
                            placeholder="Document Title"
                            value={newFile.title || ""}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                            </span>
                        <select
                            name="folderUid"
                            className={"text"}
                            value={newFile.folderUid}
                            onChange={handleChange}
                        >
                            <option value={""}>Select Folder</option>
                            {folders.map(item=>(
                                <option value={item.uid}>{item.title}</option>
                            ))}
                        </select>
                    </li>
                        <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Document</button>
                        </li>
                    </ul>
                </form>
                <p>Add Folder</p>
                <form onSubmit={onSubmitFolder}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="title"
                                className="text"
                                placeholder="Folder Title"
                                value={newFolder.title || ""}
                                onChange={handleChangeFolder}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Folder</button>
                        </li>
                    </ul>
                </form>
                <p>Current Documents</p>
                {files.map(item=>(
                    <div className={"flex"}>
                        <div className={"text-left top-bottom-padding-large"}>
                            <div><b>{item.title}</b></div>
                            {item.filesFolders != null ? <div>Folder: {item.filesFolders.title}</div> : ""}
                        </div>
                        <div className={"trash-container"}><div onClick={()=> deleteFile(item.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                    </div>
                ))}
                <p>Current Folders</p>
                {folders.map(item=>(
                    <div>
                        <div className={"flex"}>
                            <div><b>{item.title}</b></div>
                            <div className={"trash-container"}><div onClick={()=> deleteFolder(item.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                        </div>
                        <br/>
                    </div>
                ))}
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}

export default ResidentDocumentsAdmin
