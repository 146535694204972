import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import React from 'react';
import CopyFooter from "./CopyFooter";


function Gv(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    useEffect(()=>{
        document.title = `Garden Variety | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1){
            doGet("gv/new").then((res)=>{
                console.log(res);
                setData(res)
                setIsLoading(false)
            })
            setCount(1)
        }
    }, [loading, user, navigate])

    const title = (title) => {
        let titles = title.split("|")
        let titles2 = titles[0].split(",")
        return titles2[1]+" | "+titles[1]
    }

    return(<div>
        <div className={"form"}>
            {Heading("Garden Variety")}
            {isLoading ? Loading() :<div className={"login"}>
                <div className={"padding-content"}>
                    <div className={"flex-photos"}>
                {data["rsp"]["_content"]["result"]["_content"].map(items=>(
                    items.document.title.includes("Garden Variety") ?<div style={{width: "150px", paddingBottom : "15px"}}>   <a href={process.env.REACT_APP_API_ENDPOINT+"issuu-view/"+items.document.name}>
                         <img src={'http://image.issuu.com/'+items.document.documentId+'/jpg/page_1_thumb_large.jpg'} width={100}/><br/>
                        {items.document.title}
                    </a></div>:""
                ))}
                </div>
                </div>
            </div>}
        </div>
        {CopyFooter()}
    </div>)
}

export default Gv
