import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {doGet, getBlob} from "./rest";
import {Loading} from "./Loading";
import Player from "./Player";

function PrintableDirectories() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [locations, setLocations] = useState([])
    let selectedLocations = [];
    useEffect(()=>{
        document.title = `Directory | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
    },[loading, user, navigate])

    const downloadResidentDirectory =async(location)=>{
        setIsLoading(true)
        const resp = await getBlob(location)
        const url = window.URL.createObjectURL(
            new Blob([resp]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setIsLoading(false)
    }

    const teamMemberSelection = async ()=>{
        setIsLoading(true)
        const response = await doGet("celebrations/communities")
        setIsLoading(false)
        setLocations(response)
        setIsOpen(!isOpen)
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const checkboxChange = (event) =>{
        if(event.target.value === 'all'){
            const x = document.getElementsByClassName("uncheck");
            for(let i=0; i<x.length; i++) {
                x[i].checked = false;
            }
            selectedLocations = [];
            selectedLocations.push("all")
        }else{
            const x = document.getElementsByClassName("uncheckAll");
            for(let i=0; i<x.length; i++) {
                x[i].checked = false;
            }
            if(!selectedLocations.includes(event.target.value)){
                selectedLocations.push(event.target.value)
            }else{
                const index = selectedLocations.indexOf(event.target.value);
                if(index !== -1){
                    selectedLocations.splice(index, 1)
                }
            }
        }
    }

    const downloadTemDirectory = () =>{
        console.log(selectedLocations);
        if(selectedLocations.length === 0){
            alert("You must select at lest one location.  To view all please select 'Show All Locations'")
            return;
        }
        const locations = selectedLocations.join(",")
        if(locations != null && locations !== 'all'){
            downloadResidentDirectory("celebrations/directory/pdf/tm?locations="+locations)
        }else{
            downloadResidentDirectory("celebrations/directory/pdf/tm")
        }
        selectedLocations = []
        setIsOpen(false)
    }

    return(<div>
        <div className={"form"}>
            {Heading("Directory")}
            <div className={"login"}>
                <p>Full Directory Listings</p>
                <ul>
                    <li><a href={"/directory/full/resident"}>View Full Resident Directory</a> </li>
                    <li><a href={"/directory/full/staff"}>View Full Team Member Directory</a> </li>
                    <li><a href={"/directory/full/location"}>View Full Campus Directory</a> </li>
                </ul>
                <p>Printable PDF Directories</p>
                <ul>
                    <li><a href={"javascript:void(0)"} onClick={()=>downloadResidentDirectory("celebrations/directory/pdf/resident")}>Resident Printable Directory</a> </li>
                    <li><a href={"javascript:void(0)"} onClick={()=>teamMemberSelection()}>Team Member Printable Directory</a> </li>
                    <li><a href={"javascript:void(0)"} onClick={()=>downloadResidentDirectory("celebrations/directory/pdf/campus")}>Campus Printable  Directory</a> </li>
                </ul>
            </div>
            {isLoading ? Loading() :""}
        </div>
        {CopyFooter()}
        {isOpen && <Player
            content={<>
                <ul>
                    <li><input type='checkbox' value='all' className={'uncheckAll'} onChange={checkboxChange}/> Show All </li>
                    {locations.map(data =>(<li>
                        <input type='checkbox' className='uncheck' value={data.uid} onChange={checkboxChange}/>{data.name}
                    </li>))}
                    <li><button onClick={downloadTemDirectory}>Download Directory</button></li>
                </ul>
            </>}
            handleClose={togglePopup}
        ></Player>}
    </div>)
}

export default PrintableDirectories
