import React from "react";
import Sidebar from './Sidebar';

function Heading(title){
    return (
        <div className="header">
            <div className={"heading-display"}>
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                <h2>{title}</h2>
            </div>
        </div>
    );
}

export default Heading;
