import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doPOst} from "./rest";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";

function Birthdays() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [month, setMonth] = useState("")
    const [monthNumber, setMonthNumber] = useState(0)
    const [people, setPeople] = useState([])
    useEffect(()=>{
        document.title = `Birthdays | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        const date = new Date();
        setMonth(monthNames[date.getMonth()])
        setMonthNumber(date.getMonth())
        doPOst("celebrations/birthdays-new").then((data)=>{
            setPeople(data)
            setIsLoading(false)
        })
    }, [loading, user, navigate])

    const changeDateForward = async event =>{
        event.preventDefault()
            setIsLoading(true)
            const dateChange = new Date(2022, monthNumber, 1)
            const newDate = new Date(dateChange.setMonth(dateChange.getMonth()+1))
            const formDate = new FormData()
            const displayMont = newDate.getMonth()+1
            formDate.set("month", displayMont)
            const resp = await doPOst("celebrations/birthdays-new", formDate)
            setMonthNumber(newDate.getMonth())
            setMonth(monthNames[newDate.getMonth()])
            setPeople(resp)
            setIsLoading(false)
    }
    const changeDateBack = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const dateChange = new Date(2022, monthNumber, 1)
        const newDate = new Date(dateChange.setMonth(dateChange.getMonth()-1))
        const formDate = new FormData()
        const displayMont = newDate.getMonth()+1
        formDate.set("month", displayMont)
        const resp = await doPOst("celebrations/birthdays-new", formDate)
        setMonthNumber(newDate.getMonth())
        setMonth(monthNames[newDate.getMonth()])
        setPeople(resp)
        setIsLoading(false)
    }
    const getPhoto = (person)=>{
        if(person.photo != null && person.photo !== ""){
            return <img loading={'lazy'} alt={"photo"} src={"https://photos.gsv.gsrdp.org/"+person.photo} width={"175"}/>
        }
        if(person.residentGender === "female"){
            return <img loading={'lazy'} src="https://gsv.gsrdp.org/images/photo-placeholder-female-thumb.jpg" alt={"photo"} width={"175"}/>
        }
        return <img loading={'lazy'} src="https://gsv.gsrdp.org/images/photo-placeholder-male-thumb.jpg" alt={"photo"} width={"175"}/>
    }

    const birthDate = (birthday) =>{
        const date = new Date(birthday)
        const displayMonth = date.getMonth()+1
        return displayMonth+"/"+("0"+date.getDate()).slice(-2)
    }


    return(<div>
        <div className={"form"}>
            {Heading("Resident Birthdays")}
            <div className={"padding-content"}>
                <div className={"login"}>
                    <h3>
                        <a id={"back"} onClick={changeDateBack} href="#"><FontAwesomeIcon icon={faArrowCircleLeft}></FontAwesomeIcon></a>
                        {month}
                        <a id={"forward"} onClick={changeDateForward} href="#"><FontAwesomeIcon icon={faArrowCircleRight}></FontAwesomeIcon></a>
                    </h3>

                <br/>
                <div className={"flex-photos"}>
                    {people.map(person=>(<div className={"gallery-item"}>
                        {getPhoto(person)}
                        {birthDate(person.residentBirthdate)} {person.preferredName} {person.lastName}<br/>
                        {person.residentLocations.neighborhood.neighborHoodCommunityName}
                    </div>))}
                </div>
                </div>
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
    </div>)

}
export default Birthdays
