import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faMailBulk, faPerson, faPhone} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {postJson} from "./rest";


function AddUserForm() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState({})
    const [message, setMessage] = useState("")
    const [showLoading, setShowLoading] = useState(false)
    useEffect(()=>{
        document.title = `AddUser | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_users").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
    })

    useEffect(() =>(
        console.log(newUser)
    ),[newUser])

    const handleChange = event =>{
        if(event.target.name === "phone"){
            setNewUser(prevState =>({
                ...prevState,
                [event.target.name] : normalizeInput(event.target.value)
            }))
        }else{
            setNewUser(prevState =>({
                ...prevState,
                [event.target.name] : event.target.value
            }))
        }

    }
    const handleCheckBoxChange = event => {
        setNewUser(prevState =>({
            ...prevState,
            [event.target.name] : event.target.checked
        }))
    }

    const saveUser = async event =>{
        event.preventDefault()
        setMessage("")
        if(newUser.fname == null || newUser.fname === ""){
            setMessage("First Name is required.")
            return
        }
        if(newUser.lname == null || newUser.lname === ""){
            setMessage("Last Name is required.")
            return
        }
        if(newUser.email == null || newUser.email === "" || !validateEmail(newUser.email)){
            setMessage("A valid email address is required.")
            return
        }
        if(newUser.password == null || newUser.password.length < 6){
            setMessage("Please enter a valid password that is greater then 6 characters long.")
            return
        }
        setShowLoading(true);
        let response = await postJson("user-admin/add-user", newUser)

        if(response.success){
            alert(response.response)
            navigate("/user-admin")
        }else{
            setMessage(response.response)
        }
        setShowLoading(false);

    }

    const validateEmail = (email) =>{
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    return(<div>
        <div className={"form"}>
        {Heading("Add User")}
        <div id="content" className="padding-content">
            <div className={"login"}>
                <p>Add A User</p>
                {message}
                <form>
                    <ul>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="fname"
                                className="text"
                                placeholder="First Name"
                                value={newUser.fname || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="lname"
                                className="text"
                                placeholder="Last Name"
                                value={newUser.lname || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="email"
                                className="text"
                                placeholder="Email Address"
                                value={newUser.email || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="phone"
                                className="text"
                                placeholder="Phone Number"
                                value={newUser.phone || ""}
                                onChange={handleChange}
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                            </span>
                            <input
                                type="password"
                                name="password"
                                className="text"
                                placeholder="Password"
                                value={newUser.password || ""}
                                onChange={handleChange}
                            />
                        </li>
                        <li className={"flex-baselign"}>
                                    Is Future Resident:
                            <input
                                type="checkbox"
                                name="futureResident"
                                placeholder="Password"
                                value={'true'}
                                onChange={handleCheckBoxChange}
                            />
                        </li>
                        <li>
                            <br/>
                            <button name="gsvradd" value="Update" className="btn" onClick={saveUser}>Add User</button>
                        </li>
                    </ul>
                </form>
            </div>
            {showLoading ? Loading() : ""}
        </div>
        </div>
        {CopyFooter()}
    </div>)


}
export default AddUserForm
