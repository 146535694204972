
const residentNameFormatterFirstNameLastName = (data) =>{
    if(data.length === 1){
        return `${data[0]["preferredName"]} ${data[0]["lastName"]}`
    }
    const lastNameList = {}

    for(let j = 0; j < data.length; j++){
        const da = data[j]
        let newResidents = []
        if(lastNameList[da["lastName"]] !== undefined){
            newResidents = lastNameList[da["lastName"]];
        }
        newResidents.push(da)
        lastNameList[da["lastName"]] = newResidents
    }
    let g = 0
    let returnValue = "";
    for(const [key, value] of Object.entries(lastNameList)){
        g = g +1;
        for(let i = 0 ; i < value.length; i++){
            const rn = value[i]
            returnValue += rn.preferredName
            if(i < value.length-1){
                returnValue += " & "
            }else{
                returnValue += " "+rn.lastName
                if(g !== lastNameList.length){
                    returnValue += " & "
                }
            }
        }
    }
    if(returnValue.endsWith(" & ")){
        return returnValue.substring(0,returnValue.length-3)
    }
    return returnValue;

}

const residentNameFormatterLastNameFirstname = (data) =>{
    if(data.length === 1){
        return `${data[0].lastName}, ${data[0].preferredName}`
    }
    const lastNameList = {}

    for(let j = 0; j < data.length; j++){
        const da = data[j]
        let newResidents = []
        if(lastNameList[da["lastName"]] !== undefined){
            newResidents = lastNameList[da["lastName"]];
        }
        newResidents.push(da)
        lastNameList[da["lastName"]] = newResidents
    }

    let g = 0
    let returnValue = "";
    for(const [key, value] of Object.entries(lastNameList)){
        g = g +1;
        returnValue += `${key}, `
        for(let i = 0 ; i < value.length; i++){
            const rn = value[i]
            returnValue += rn.preferredName
            if(i < value.length-1){
                returnValue += " & "
            }else{
                if(g !== lastNameList.length){
                    returnValue += " & "
                }
            }
        }
    }
    if(returnValue.endsWith(" & ")){
        return returnValue.substring(0,returnValue.length-3)
    }
    return returnValue;
}


export {
    residentNameFormatterFirstNameLastName,
    residentNameFormatterLastNameFirstname
}
