import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doGetString} from "./rest";
import CopyFooter from "./CopyFooter";
import Heading from "./Heading";
import {Loading} from "./Loading";

function TouchtownSlides() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        doGetString("touchtown").then(url => {
            setUrl(url)
            setIsLoading(false)
        });
    }, [loading, user, navigate])

    return(<div style={{position: 'relative', height: '100vh', width: '100%' }}>

        {Heading("Touchtown Slides")}

        {isLoading ? <Loading /> : <iframe style={{position: 'absolute', top: 80, left: 0, height: '100%', width: '100%'}}  src={url}/>}

    </div>)
}
export default TouchtownSlides
