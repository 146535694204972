import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSearch, faTimesCircle, faUser} from "@fortawesome/free-solid-svg-icons";

function UserAdmin(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoaded, setIdLoaded] = useState(true);
    const [users, setUsers] = useState([])
    const [userFilter, setUserFilter] = useState([])
    const [count, setCount] = useState(0);
    const [q, setQ] = useState("");
    useEffect(() => {
        document.title = `User Admin | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        checkUserAdmin().then((resp)=>{
            if(!resp){
                navigate("/dashboard")
            }
            if(count < 1) {
                doGet("user-admin/").then((result) => {
                    console.log(result)
                    setUsers(result);
                    setUserFilter(result)
                    setIdLoaded(false);
                });
                setCount(1);
            }
        })
    }, [user, loading]);

    const checkUserAdmin = async () =>{
        return await hasAuthority("ROLE_users")
    }

    const onChanged = event =>{
        setQ(event.target.value)
        let newData = []
        for(let i = 0; i < userFilter.length; i++){
            if(userFilter[i].lname.toLowerCase().includes(event.target.value) || userFilter[i].fname.toLowerCase().includes(event.target.value) || userFilter[i].email.toLowerCase().includes(event.target.value)){
                newData.push(userFilter[i])
            }
            console.log(userFilter[i].lname)
        }
        setUsers(newData)
    }

    return(<div><div className={"form"}>
        {Heading("User Admin")}
        <div id="content" className="padding-content">
            {isLoaded ? Loading() : <div>
                <div className={"login"}>
                    <p>Edit our users</p>
                    <div className="sign logout-container flex-space-between">
                        <a className={"button-icon"} href="/add-user">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><br/>
                            Add User
                        </a>
                        <a className={"button-icon"} href="/future-residents">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><br/>
                            Future Residents
                        </a>
                    </div>
                    <br/><br/>
                    <div className={"flex-beside"}>
                        <span className="un">
                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                        <input type={"text"}
                               name={"search"}
                               className={"text"}
                               placeholder={"Search Our Users"}
                               value={q || ""}
                               onChange={onChanged}
                        />
                    </div>
                    <br/><br/>
                    <div className={"user-display"}>
                        <div>Name</div>
                        <div>Admin User</div>
                    </div>
                    {users.map(user=>(
                        <a href={"/edit-user/"+user.uid}>
                            <div className={"user-display"}>
                                <div className={"align-text-left"}>
                                <div>{user.lname}, {user.fname}</div>
                                    {user.email}
                                </div>
                                <div><FontAwesomeIcon icon={user.admin === "1" ? faCheckCircle : faTimesCircle}></FontAwesomeIcon></div>
                            </div>
                            <br/>
                        </a>
                    ))}
                </div>

            </div>}

        </div>
    </div>
        {CopyFooter()}
    </div>)



}

export default UserAdmin
