import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import React from 'react';
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faCalendar, faFileWord, faImage, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "./Loading";
import {doGet, postJson} from "./rest";

function GVAdmin(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [newGV, setNewGV] = useState({})
    const [currentGV, setCurrentGV] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(()=>{
        document.title = `Edit Garden Variety | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_gardenVariety").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        doGet("gv/all").then((resp)=>{
            setCurrentGV(resp)
            setIsLoading(false)
        })
    },[user, loading])

    const handleChange = event =>{
        setNewGV(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    const onFocus = event =>{
        event.target.type = "date";

    }

    const deleteCat = async (id) =>{
        if(window.confirm("Are you sure you want to delete this?  It cannot be undone.")){
            setIsLoading(true);
            const response = await doGet("gv/delete/"+id)
            setCurrentGV(response)
            setIsLoading(false)
        }
    }

    const submitForm = async event =>{
        event.preventDefault()
        setIsLoading(true)
        const resp = await postJson("gv/new", newGV)
        alert(resp.response)
        if(resp.success){
            const refresh = await doGet("gv/all")
            setCurrentGV(refresh)
            setNewGV({})
            event.target.reset();
        }
        setIsLoading(false)

    }



    return(<div>
        <div className={"form"}>
            {Heading("Add Garden Variety")}
            <div className="login">
                <form onSubmit={submitForm}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="title"
                                className="text"
                                placeholder="Publication Title"
                                value={newGV.title || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileWord}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="link"
                                className="text"
                                placeholder="Issuu Pub Identifier "
                                value={newGV.link || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="thumb"
                                className="text"
                                placeholder="Issuu Thumb "
                                value={newGV.thumb || ""}
                                onChange={handleChange}
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="publishDate"
                                className="text"
                                placeholder="Publish Date"
                                value={newGV.publishDate || ""}
                                onChange={handleChange}
                                onFocus={onFocus}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Publication</button>
                        </li>
                    </ul>
                </form>
                <p>Current</p>
                {currentGV.map(items =>(
                    <div>
                        <div className={"flex"}>
                            <div><b>{items.title}</b></div>
                            <div className={"trash-container padding-left"}><div onClick={()=>deleteCat(items.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                        </div>
                        <br/>
                    </div>
                ))}
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}
export default GVAdmin
