import React, {useEffect, useRef, useState} from "react";
import {doGet} from "./rest";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css"
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {Loading} from "./Loading";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import Player from "./Player";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

function PhotoGallery(){

    const [user, loading] = useAuthState(auth);
    const [gallery, setGallery] = useState([]);
    const [isLoaded, setIdLoaded] = useState(true);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState("");
    const [userw, setUserw] = useState("")
    const [count, setCount] = useState(0);
    const [desc, setDesc] = useState("")
    const [photoId, setPhotoId] = useState("")
    const [first, setFirst] = useState(true)
    const [last, setLast] = useState(false)
    const [page, setPage] = useState(0);
    useEffect(()=>{
        document.title = `Photo Gallery | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1) {
            doGet("gallery/page?page="+page).then((result) => {
                setGallery(result.content);
                setFirst(result.first ?? false)
                setLast(result.last ?? false)
                setIdLoaded(false);
            });
            setCount(1);
        }
    },[user, loading, navigate])

    const navigatePhotos = async (number) =>{
        console.log(number)
        setPage(number)
        setIdLoaded(true)
        const result = await doGet("gallery/page?page="+number)
        setGallery(result.content);
        setFirst(result.first ?? false)
        setLast(result.last ?? false)
        setIdLoaded(false)
    }

    const togglePopup = (url, desc, uid, photoId) => {
        setUrl(url);
        setDesc(desc)
        setUserw(uid)
        setPhotoId(photoId)
        setIsOpen(!isOpen);
    }

    const deletePhoto = async (id) =>{
        if(window.confirm("Are you sure you want to delete this photo?")){
            setIdLoaded(true)
            const resp = await doGet("gallery/delete/"+id)
            alert(resp.response)
            if(resp.success){
                const photos = await doGet("gallery/")
                setGallery(photos)
                setUrl("");
                setDesc("")
                setUserw("")
                setPhotoId("")
                setIsOpen(false);
            }
            setIdLoaded(false)
        }
    }

    return(
        <div>
        <div className="form">
            {Heading("The Gallery")}
            <div id="content" className="padding-content">
                {isLoaded ? Loading() : <div><div className="login">
                    <p>Collections of photos from around campus to be shared with others. Add your favorites today!</p>
                    <br/><br/>

                <div className="flex-photos">
                    {gallery.map(item=>(
                        <div className="gallery-item">
                            <a href="javascript:void(0)" onClick={()=>togglePopup(process.env.REACT_APP_URL+"/uploads/photogallery/full/"+item.pimage, item.ptitle, item.users.uid, item.id)}>
                                <img src={process.env.REACT_APP_URL+"/uploads/photogallery/thumbs/"+item.pthumb} alt="photo" className={"photo-gallery-photo"}/>
                                <div>Posted By: {item["users"].fname} {item["users"].lname}</div>
                                <div>{item.ptitle}</div>
                            </a>
                        </div>
                    ))}

                </div>
                    <br/>
                    <div className={"flex-space-between"}>
                        {first ? <div></div> :<div> <a onClick={()=>navigatePhotos(page-1)}>
                           <FontAwesomeIcon icon={faCircleArrowLeft}></FontAwesomeIcon> Previous Page
                        </a></div>}
                        {last ? <div></div> :<div> <a onClick={()=>navigatePhotos(page+1)}>
                            Next Page <FontAwesomeIcon icon={faCircleArrowRight}></FontAwesomeIcon>
                        </a></div>}
                    </div>
                    <br/>
                    <div>
                        <button className="btn" onClick={()=>{
                            navigate("/gallery-add")
                        }}>+add</button>
                    </div>
                </div>

                </div>}
            </div>

        </div>
            {CopyFooter()}
            {isOpen && <Player
                content={<>
                   <img src={url} alt={"image not found"} className={"img-max-height"} width="100%"/>
                    <div className={"title"}>{desc}</div>
                   {userw === user.uid ?<div><a href={"javascript:void(0)"} onClick={()=>deletePhoto(photoId)}>Delete Your Photo</a> </div>:""}
                </>}
                handleClose={togglePopup}
            ></Player>
            }
        </div>
    );



}

export default PhotoGallery;
