import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import React from 'react';
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileText} from "@fortawesome/free-solid-svg-icons";

function Folders(){
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    useEffect(()=>{
        document.title = `Resident Documents | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1){
            doGet("files/folder/"+id).then((res)=>{
                setData(res)
                setIsLoading(false)
            })
            setCount(1)
        }
    }, [loading, user, navigate])

    return(<div>
        <div className={"form"}>
            {Heading("Resident Documents")}
            {isLoading ? Loading() :<div className={"login"}>
                <h2>{data["folder"]["title"]}</h2>
                {data["files"].map(items=>(
                    <div className="flex-filefolders"><FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                        <a className="folder-padding" href={process.env.REACT_APP_URL+"/pdf/web/viewer.html?file=/files/"+items.url} target="_blank">{items.title}</a>
                    </div>
                ))}
            </div>}
        </div>
        {CopyFooter()}
    </div>)

}
export default Folders
