import { initializeApp} from "firebase/app";
import {
 getAuth,
 signInWithEmailAndPassword,
 signOut,
} from "firebase/auth";
import {
 getFirestore,
} from "firebase/firestore";
import { getAnalytics, initializeAnalytics} from "firebase/analytics";


import {setPersistence, browserSessionPersistence, browserLocalPersistence} from "firebase/auth";
import {clearCookies} from "./MyCookies";





const firebaseConfig = {
 apiKey: process.env.REACT_APP_FIREBASE_API_KEY,

 authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,

 databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,

 projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,

 storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,

 messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,

 appId: process.env.REACT_APP_FIREBASE_APP_ID,

 measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

const analytics = getAnalytics(app);

const logInWIthEmailAndPassword = async (email, password, persistance) =>{
 if(persistance){
  await setPersistence(auth, browserLocalPersistence);
 }else{
  await setPersistence(auth, browserSessionPersistence);
 }
 try{
  await clearCookies()
  await signInWithEmailAndPassword(auth, email, password);
 }catch (e) {
  console.log(e)
  if(e.toString().toLocaleLowerCase().includes("auth/wrong-password")){
   return "Password is incorrect";
  }
  if(e.toString().toLocaleLowerCase().includes("auth/too-many-requests")){
   return "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
  }
  if(e.toString().toLocaleLowerCase().includes("auth/user-not-found")){
   return "Email address not found";
  }
  return "Error logging in.  Please try again."
 }
}

const logOut = async () => {
 await clearCookies()
 signOut(auth);
}

const getUid =  () =>{
 return auth.currentUser.uid;
}

const authToken = async () =>{
 return await auth.currentUser.getIdToken();
}
export {
 analytics,
 auth,
 db,
 authToken,
 logOut,
 logInWIthEmailAndPassword,
 getUid
};
