import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from 'firebase/analytics';
import {analytics} from "./firebase";
const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname)
        logEvent(analytics, 'screen_view', {
            firebase_screen: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
            firebase_screen_class: 'firebase-routes-analytics', // <- This name is up to you
        });
    }, [location]);
};

export default usePageTracking;
