import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {doGetVacation} from "./rest";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";

function ViewVacations() {
    const [user, loading] = useAuthState(auth);
    const [vacations, setVacations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `My Vacations | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGetVacation("mine").then((data)=>{
            setVacations(data)
            setIsLoading(false)
        })
    },[loading, user, navigate])

    const deleteVac = async (id) =>{
        if(window.confirm("Are you sure you want to remove this vacation?")){
            setIsLoading(true)
            try {
                await doGetVacation("delete/" + id)
            }catch (e){
            }
            const newOnes = await doGetVacation("mine")
            setVacations(newOnes)
            setIsLoading(false);
        }
    }

    const viewVacation = (id) =>{
        navigate("/vacations/update", {state : id})
    }

    return(<div>
        <div className={"form"}>
            {Heading("My Vacations")}
            <div className={"login"}>
                <div className={"bottom-padding"}>
                <a href={"/vacations/new"}>Add New</a>
                </div>
                {isLoading ? "" : vacations.length === 0 ?<div>You currently have no upcoming vacations.</div>
                    :
                    vacations.map(data =>(<div className={"bottom-padding"} >
                        <div className={"flex"}>
                            <div onClick={()=>viewVacation(data)}><b>{data.startDate} to {data.endDate}</b>
                                <div className={"text-left"}>{data.address ?? ""}</div>
                            </div>
                            <div className={"trash-container padding-left"}><div onClick={()=>deleteVac(data.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                        </div>
                    </div>))
                }
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}

export default ViewVacations
