import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import newResidents from "./icons/CATIE_ICONS-NewResidents.png"
import birthdays from "./icons/CATIE_ICONS-ResidentBirthdays.png"
import life from "./icons/CATIE_ICONS-CelebrateALife.png"
function Celebrations() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `Celebrations | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
    },[loading, user, navigate])

    return(<div>
        <div className={"form"}>
            {Heading("Celebrations")}
            <div id="content" className="padding-content login">
                <div className="flex">
                    <div className="button-container">
                        <a href="/celebrations/new-residents">
                            <img className={"new-button-size"} src={newResidents}/>
                        </a>
                    </div>
                    <div className="button-container">
                        <a href="/celebrations/birthdays">
                            <img className={"new-button-size"} src={birthdays}/>
                        </a>
                    </div>
                    <div className="button-container">
                        <a href="/celebrations/celebrate-a-life">
                            <img className={"new-button-size"} src={life}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        {CopyFooter()}
    </div>)
}

export default Celebrations
