import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faHouse,
    faPerson,
    faPhone,
    faTree
} from "@fortawesome/free-solid-svg-icons";
import React from 'react';

import {doPostJsonVacation, postJson} from "./rest";

function VacationForm() {

    let { state } = useLocation();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [vacationForm, setVacationForm] = useState({})

    useEffect(()=>{
        document.title = `Resident Vacation Form | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(state == null){
            setVacationForm(prevState =>({
                ...prevState,
                billing: "B1",
                facility: "F1",
                mail: "M1",
            }))
        }else{
            setVacationForm(state)
        }
    },[loading, user, navigate])
    const handleChange = event =>{
        setVacationForm(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }
    const onFocus = event =>{
        event.target.type = "date";
    }

    const handleCatieChange = event =>{
        const value = event.target.value === "true";
        setVacationForm({
            ...vacationForm,
            catie: value
        });
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(vacationForm.catie === undefined){
            window.alert("Please indicate if you are part of the CATIE check in group.")
            return
        }
        setIsLoading(true)
        vacationForm.name = user.displayName
        vacationForm.myGSVLink = user.uid
        if(vacationForm.address == null){
            vacationForm.address = ""
        }
        const response = await doPostJsonVacation("new", vacationForm)
        alert(response.message)
        if(response.status){
            navigate("/vacations")
        }
        setIsLoading(false)
    }


    return(<div>
        <div className={"form"}>
            {Heading("Vacation Information Form")}
            <div className={"login"}>
                <form onSubmit={onSubmit}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="homeAddress"
                                className="text"
                                placeholder="Cottage or Apt Number"
                                value={vacationForm.homeAddress || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="phone"
                                className="text"
                                placeholder="Vacation Phone Number"
                                value={vacationForm.phone || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faTree}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="address"
                                className="text"
                                placeholder="Vacation Address"
                                value={vacationForm.address || ""}
                                onChange={handleChange}
                            />
                        </li>
                        <li>
                            <p>I am part of the morning check in program in which I press the Check In button on my CATIE each morning.  I will not be checking in during my vacation and would like this feature disabled during my absence.
                            <div>
                                <div style={{textAlign: "left"}}>
                            <input type={"radio"} name={"catie"} value={"true"} onChange={handleCatieChange} checked={vacationForm?.catie === true}/>
                                Yes, my check ins should be disabled during my absence
                                </div>
                                <div style={{textAlign: "left", marginBottom: 10}}>
                            <input type={"radio"} name={"catie"} value={"false"} onChange={handleCatieChange} checked={vacationForm?.catie === false}/>
                                No, I am not currently participating in the check in program
                                </div>
                            </div>
                                <br/><br/></p>
                        </li>
                        <li></li>
                        <li className="flex" style={{paddingTop: 10}}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="startDate"
                                className="text"
                                placeholder="Date Leaving"
                                value={vacationForm.startDate || ""}
                                onChange={handleChange}
                                onFocus={onFocus}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="endDate"
                                className="text"
                                placeholder="Date Returning"
                                value={vacationForm.endDate || ""}
                                onChange={handleChange}
                                onFocus={onFocus}
                                required
                            />
                        </li>
                    </ul>
                    <div className={"text-left"}>
                    <h3>Billing Instructions</h3>
                    <input type="radio" name="billing" id="billing1" value="B1" onChange={handleChange} checked={vacationForm.billing === "B1"}/>
                    I will leave pre-dated checks at the Front Desk for the following months:
                        {vacationForm.billing === "B1" ? <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="billingMonth"
                                    className="text"
                                    placeholder="Which Months"
                                    value={vacationForm.billingMonth || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                        </ul>:""}
                    <br/>
                    <input type="radio" name="billing" id="billing1" value="B2" onChange={handleChange} checked={vacationForm.billing === "B2"}/>
                    Please send my monthly statement to my vacation address for the month(s):
                        {vacationForm.billing === "B2" ? <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="billingMonth2"
                                    className="text"
                                    placeholder="Which Months?"
                                    value={vacationForm.billingMonth2 || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                        </ul>:""}
                        <br/>
                        <input type="radio" name="billing" id="billing1" value="B3" onChange={handleChange} checked={vacationForm.billing === "B3"}/>
                        Do not send my monthly fees, I will.
                        <br/>
                        <input type="radio" name="billing" id="billing1" value="B4" onChange={handleChange} checked={vacationForm.billing === "B4"}/>
                        Have the Bank send a check each month.
                        <br/>
                        <input type="radio" name="billing" id="billing1" value="B5" onChange={handleChange} checked={vacationForm.billing === "B5"}/>
                        My monthly fees are automatically deducted.
                        <br/>
                        <input type="radio" name="billing" id="billing1" value="B6" onChange={handleChange} checked={vacationForm.billing === "B6"}/>
                        I will send a check for each month.
                        <br/>
                        <input type="radio" name="billing" id="billing1" value="B7" onChange={handleChange} checked={vacationForm.billing === "B7"}/>
                        I will be gone between billing periods.
                    </div>
                    <div className={"text-left"}>
                        <h3>Facility Services</h3>
                        <input type="radio" name="facility" id="billing1" value="F1" onChange={handleChange} checked={vacationForm.facility === "F1"}/>
                        Facility Services has my permission to enter my home while I'm away, if needed.
                        <br/>
                        <input type="radio" name="facility" id="billing1" value="F2" onChange={handleChange} checked={vacationForm.facility === "F2"}/>
                        Facility Services DOES NOT have permission to enter my home while I am away without contacting the following GSV neighbor:
                        {vacationForm.facility === "F2" ? <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="neighborName"
                                    className="text"
                                    placeholder="Neighbor Name"
                                    value={vacationForm.neighborName || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="neighborPhone"
                                    className="text"
                                    placeholder="Neighbor Phone Number"
                                    value={vacationForm.neighborPhone || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                        </ul>:""}
                    </div>
                    <div className={"text-left"}>
                        <h3>In House Memo/Mail Instructions:</h3>
                        <input type="radio" name="mail" id="billing1" value="M1" onChange={handleChange} checked={vacationForm.mail === "M1"}/>
                        Hold at the Front Desk/Mail Room and upon returning I will pick it up.
                        <br/>
                        <input type="radio" name="mail" id="billing1" value="M2" onChange={handleChange} checked={vacationForm.mail === "M2"}/>
                        Send as normal.
                    </div>
                    <ul>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">{state == null ? "Submit Vacation Form" : "Update Vacation Form"}</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        {isLoading ? Loading() :""}
        {CopyFooter()}
    </div>)
}
export default VacationForm
