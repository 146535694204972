import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import {doGet} from "./rest";
import {Loading} from "./Loading";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import CopyFooter from "./CopyFooter";

function FutureResidentAdmin() {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([])

    useEffect(()=>{
        document.title = `Future Resident Admin | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_users").then((resp)=>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        doGet("user-admin/future-residents").then((resp)=>{
            setIsLoading(false)
            setUsers(resp)
        });
    },[loading, user, navigate])

    const deleteUser = async (id) =>{
        if(window.confirm("Are you sure you want to delete this?  If they have already signed up for an account, this will not delete it.")){
            setIsLoading(true)
            const resp = await doGet("user-admin/future-residents/delete/"+id);
            setIsLoading(false)
            setUsers(resp)
        }
    }

    return(<div>
        <div className={"form"}>
            {Heading("Future Residents")}
            <div id={"content"}>
                <div className={"login"}>
                    <br/>
                    <div className="sign logout-container">
                        <a className={"button-icon"} href="/invite-future-resident">
                            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><br/>
                            Invite Future Resident
                        </a>
                    </div>
                {isLoading ? Loading() : <div>
                    {users.map(data=>(
                        <div>
                            <div className={"user-display"}>
                                <a href={"/edit-future/"+data.id}>
                                <div className={"align-text-left"}>
                                    <div>{data.lastName}, {data.firstName}</div>
                                    {data.emailAddress}
                                </div>
                                </a>
                                <div onClick={()=>deleteUser(data.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
                            </div>
                            <br/>
                        </div>
                    ))}
                </div>}
                </div>
            </div>
        </div>
        {CopyFooter()}
    </div>)

}

export default FutureResidentAdmin
