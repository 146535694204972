import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';

function RightNow() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = `RightNow Media | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
    },[loading, user, navigate])


    return(<div>
        <div className={"form"}>
            {Heading("RightNow Media")}
            <div className={"login"}>
                <p>RightNow Media@Work is an online video library that you can access from your computer, your smart TV or your smart phone. All you need to do is click through and register for your FREE account.</p>

            <br/>
            <div className={"flex-even"}>

                <a href="https://login.rightnowmedia.org/" target="_blank">
                    <div>
                        Login
                    </div>
                </a>
                    <a href="https://www.gardenspotvillage.org/media/rightnow-media-work-intro-video/" target="_blank" rel={"noreferrer"}>
                        <div>
                            Sign Up
                        </div>
                    </a>
            </div>
            </div>
        </div>
        {CopyFooter()}
    </div>)
}
export default RightNow
