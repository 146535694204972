import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import React from 'react';
import {doGet} from "./rest";

function ExpiredListings() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [myListing, setMyListings] = useState([])
    useEffect(()=>{
        document.title = `Update Listing | The Marketplace | ${process.env.REACT_APP_TITLE}`
        if(loading){
            // show loading screen
            return;
        }
        if(!user) navigate("/");
        doGet("marketplace/expired"). then((resp)=>{
            setMyListings(resp)
            setIsLoading(false)
        })
    }, [loading, user, navigate])

    const expireDate = (date) =>{
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);
        const dateObject = new Date(month+"/"+day+"/"+year)
        let updateDate = new Date(month+"/"+day+"/"+year)
        updateDate.setDate(dateObject.getDate() + 60)
        const month1 = updateDate.getMonth()+1
        return "Expired: "+month1+"/"+updateDate.getDate()+"/"+updateDate.getFullYear();
    }

    const renewItem = async (id) =>{
        setIsLoading(true)
        const repose = await doGet("marketplace/renew/"+id)
        if(repose == null){
            alert("Something has gone wrong, we were unable to renew your listing.")
        }else{
            setMyListings(repose)
        }
        setIsLoading(false)
    }

    const deleteItem = async (id) =>{
        if(window.confirm("Are you sure you want to delete this item?")){
            setIsLoading(true)
            const response = await doGet("marketplace/delete/p/"+id)
            if(response == null){
                alert("Something has gone wrong, we were unable to delete your listing.")
            }else{
                setMyListings(response)
            }
            setIsLoading(false)
        }
    }

    return (<div>
        <div className={"form"}>
            {Heading("Your Expired Listings")}
            <div className={"login"}>
                <p>See your expired marketplace listings. Renew them for another 60 days or deleted them.</p>
                {myListing.map(data=>(
                    <div className={"text-left"} style={{paddingBottom:"30px"}}>
                        <div><a href={"/marketplace-edit/"+data.uid}>{data.ptitle}</a> </div>
                        <div>{expireDate(data.postDate)}</div>
                        <div><a href={"javascript:void(0)"} onClick={()=>renewItem(data.id)}>Renew for 60 days</a><span className={"padding-left"}></span><a href={"javascript:void(0)"} onClick={()=>deleteItem(data.id)}>Delete This Item</a> </div>
                    </div>
                ))}
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
    </div>)
}
export default ExpiredListings
