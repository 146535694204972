import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {Loading} from "./Loading";
import {doGet, doPOst, postJson} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faFileText,
    faListNumeric,
    faPaperclip,
    faPhotoFilm, faTrashCan
} from "@fortawesome/free-solid-svg-icons";


function MeetingMinutesAdmin(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [cat, setCat] = useState([]);
    const [meeting, setNewMeeting] = useState({})
    const [selectedFile, setSelectedFile] = useState(null);
    const [current, setCurrent] = useState({})
    const [newcat, setNewCat] = useState({})
    useEffect(()=>{
        document.title = `Admin | Meeting Minutes | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count === 0){
            doGet("meeting/categories").then((res)=>{
                setCat(res)
                setIsLoading(false)
            })
            doGet("meeting/all-documents").then((res)=>{
                setCurrent(res)
                setIsLoading(false)
            })


            setCount(1)
        }

        hasAuthority("ROLE_meetingMinutes").then((res)=>{
            if(!res){
                navigate("/");
            }
        })

    },[loading, user, navigate, count])

    const handleChange = event =>{
        setNewMeeting(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }
    const handleChangeCat = event =>{
        setNewCat(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }
    const onFocus = event =>{
        event.target.type = "date";
    }

    const newCategory = async event =>{
        event.preventDefault()
        if(newcat.name == null){
            alert("Category Title cannot be blank")
            return
        }
        if(newcat.defaultListingNum != null && isNaN(newcat.defaultListingNum)){
            alert("Default Number of Listing must be a number")
            return
        }

        setIsLoading(true)

        const resp = await postJson("meeting/save-category", newcat)

        setCat(resp)

        setIsLoading(false)

        alert("Your new category has been added")

        setNewCat({})
        event.target.reset();

    }
    const onSubmit = async event =>{
        event.preventDefault();

        if(meeting.connector == null){
            alert("Please select a category to continue")
            return
        }

        if(meeting.title == null){
            alert("Please specify a name to continue")
            return
        }
        if(meeting.date == null){
            alert("Please select the date of the meeting to continue")
            return
        }
        setIsLoading(true)
        const formdate = new FormData();
        formdate.set("file", selectedFile)
        formdate.set("date", meeting.date)
        formdate.set("connector", meeting.connector)
        formdate.set("title", meeting.title)
        const resp = await doPOst("meeting/save-new", formdate)
        alert(resp.response)
        if(resp.success){
            setNewMeeting({})
            setSelectedFile(null)
            event.target.reset()
            const minutes = await doGet("meeting/all-documents")
            setCurrent(minutes);
        }
        setIsLoading(false)
    }

    const renderDocs = () =>{
        return Object.keys(current).map((item, index)=>{
            return(<div>
                {current[item].map(items=>(
                    <div className={"flex"}><div className={"text-left top-bottom-padding-large"}>
                        <div><b>{items.title}</b></div>
                        <div>{item}</div>
                    </div><div className={"trash-container"}><div onClick={()=>deleteDocument(items.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div> </div>
                ))}
            </div>)
        })
    }

    const renderCategory = () =>{
        return(
            cat.map(items=>(
                <div>
                <div className={"flex"}>
                    <div><b>{items.name}</b></div>
                    <div className={"trash-container"}><div onClick={()=>deleteCat(items.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                </div>
                    <br/>
                </div>
            ))
        )
    }

    const deleteDocument = async (id) =>{
        if(window.confirm("Are you sure you want to delete this item?  It cannot be undone.")){
            setIsLoading(true)
            const response = await doGet("meeting/delete-doc/"+id)
            setCurrent(response)
            setIsLoading(false)
        }
    }
    const deleteCat = async (id) =>{
        if(window.confirm("Are you sure you want to delete this category?  This will also remove all of its documents.")){
            setIsLoading(true)
            const resp = await doGet("meeting/delete-cat/"+id)
            setCat(resp)
            setIsLoading(false);
        }
    }

    return(<div>
        <div className={"form"}>
            {Heading("Meeting Minutes Admin")}
            <div className={"login"}>

                <form onSubmit={onSubmit}>
                    <ul>
                        <li className={"flex"}>
                            <span className="un">
                                    <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                            </span>
                            <select
                                name="connector"
                                className={"text"}
                                value={meeting.connector}
                                onChange={handleChange}
                            >
                                <option></option>
                                {cat.map(item=>(
                                    <option value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="title"
                                className="text"
                                placeholder="Document Title"
                                value={meeting.title || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="date"
                                className="text"
                                placeholder="Date of Meeting"
                                value={meeting.date || ""}
                                onChange={handleChange}
                                onFocus={onFocus}
                                required
                            />
                        </li>
                        <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Document</button>
                        </li>
                    </ul>
                </form>
                <form onSubmit={newCategory}>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="name"
                                className="text"
                                placeholder="Category Title"
                                value={newcat.name || ""}
                                onChange={handleChangeCat}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faListNumeric}></FontAwesomeIcon>
                                </span>
                            <input
                                type="number"
                                name="defaultListingNum"
                                className="text"
                                placeholder="Default Number of Listings"
                                value={newcat.defaultListingNum || ""}
                                onChange={handleChangeCat}
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Category</button>
                        </li>
                    </ul>
                </form>

                <p>Manage Meeting Minutes</p>
                {renderDocs()}
                <p>Manage Category</p>
                {renderCategory()}
            </div>
    </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)

}

export default MeetingMinutesAdmin
