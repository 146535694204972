import {useAuthState} from "react-firebase-hooks/auth";
import {auth, logOut} from "./firebase";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {doGet, doPOst, postJson} from "./rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock, faPerson, faPhone} from "@fortawesome/free-solid-svg-icons";
import {hasAuthority} from "./MyCookies";
import {signOut} from "firebase/auth";

function Settings() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [userSettings, setUserSettings] = useState({})
    const [futureResident, setFutureResident] = useState(true)
    useEffect(()=>{
        document.title = `Settings | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet("settings").then((resp)=>{
            hasAuthority("ROLE_RESIDENT").then((resp2)=>{
                setFutureResident(!resp2)
                setUserSettings(resp)
                setIsLoading(false)
            })
        })
    },[user, loading, navigate])

    const handleChange = event =>{
        if(event.target.name === "phone"){
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : normalizeInput(event.target.value)
            }))
        }else{
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : event.target.value
            }))
        }

    }
    const validateEmail = (email) =>{
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };
    const handleCheckBoxChange = event =>{
        if(userSettings[event.target.name] === "0"){
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : "1"
            }))
        }else{
            setUserSettings(prevState =>({
                ...prevState,
                [event.target.name] : "0"
            }))
        }


    }

    const deleteAccount = async event =>{
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete your account?  This cannot be undone.")){
            const password = window.prompt(`To delete your account, please enter your password for ${process.env.REACT_APP_TITLE} below, then press the submit button.  This action cannot be undone, and you would need to crate a new account if you want to sign back in.`)
            if(password != null){
                setIsLoading(true)
                const formData2 = new FormData();
                formData2.set("password", password)
                const response = await doPOst("settings/delete-account", formData2)
                window.alert(response.response);
                if(response.success){
                    await logOut()
                    navigate("/")
                }
            }
        }
    }

    const handleSubmit = async event =>{
        event.preventDefault()
        if(userSettings.fname === null || userSettings.fname ===""){
            alert("First Name is required")
            return
        }
        if(userSettings.lname === null || userSettings.lname ===""){
            alert("Last Name is required")
            return
        }
        if(!validateEmail(userSettings.email)){
            alert("A valid email address is required.")
            return
        }
        if(userSettings.phone === null || userSettings.phone ===""){
            alert("Phone Number is required")
            return
        }
        if(((userSettings.pw != null && userSettings.pw !== "")  || (userSettings.pw2 != null && userSettings.pw2 !== "")) && (userSettings.pw !== userSettings.pw2 || userSettings.pw.length < 6)){
            alert("Passwords must match and be at least 6 characters to change your password")
            return
        }

        setIsLoading(true)
        const resp = await postJson("settings/update", userSettings);
        alert(resp.response)
        setIsLoading(false)
    }

    return(<div>
        <div className={"form"}>
            {Heading("Account Settings")}
            <div className={"login"}>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="fname"
                                className="text"
                                placeholder="First Name"
                                value={userSettings.fname || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="lname"
                                className="text"
                                placeholder="Last Name"
                                value={userSettings.lname || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="email"
                                className="text"
                                placeholder="Email Address"
                                value={userSettings.email || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="phone"
                                className="text"
                                placeholder="Phone Number"
                                value={userSettings.phone || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <br/><br/>
                        {!futureResident ?<div>
                        Email Subscription Settings
                        <br/>
                        <li>
                            <input type={"checkbox"} name={"notifyMarketplace"} value={"1"} onChange={handleCheckBoxChange} checked={userSettings.notifyMarketplace === "1"}/>
                            Marketplace Updates
                        </li>
                        <li>
                            <input type={"checkbox"} name={"notifyCommunityConnections"} value={"1"} onChange={handleCheckBoxChange} checked={userSettings.notifyCommunityConnections === "1"}/>
                            Community Connections Updates
                        </li>
                        <br/><br/>
                        </div> : ''}
                        Change Password
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                            <input
                                type="password"
                                name="pw"
                                className="text"
                                placeholder="User Password"
                                value={userSettings.pw}
                                onChange={handleChange}
                                autoComplete={"off"}
                            />
                        </li>
                        <li className={"flex"}>
                                <span className="un">
                                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                </span>
                            <input
                                type="password"
                                name="pw2"
                                className="text"
                                placeholder="Repeat Password"
                                autoComplete={"off"}
                                value={userSettings.pw2}
                                onChange={handleChange}
                            />
                        </li>
                        <br/>
                        <li>
                            <input type="submit" name="gsvrupdate" value="Update" className="btn"/>
                        </li>
                    </ul>
                </form>
                <ul>
                    <li>
                        <input onClick={deleteAccount} type="submit" name="gsvrupdate" value="Delete My Account" className="btn"/>
                    </li>
                </ul>
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}
export default Settings
