import {doGet, doPOst} from "./rest";
import {hasAuthority} from "./MyCookies";
import {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import Heading from "./Heading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFileText, faPhotoFilm, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {Loading} from "./Loading";

function TravelAdmin() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [travel, setTravel] = useState({})
    const [current, setCurrent] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(()=>{
        document.title = `Travel With Purpose Admin | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        hasAuthority("ROLE_travelWithPurpose").then((res)=>{
            if(!res){
                navigate("/dashboard")
            }
        })
        doGet("travel/all").then((data)=>{
            setCurrent(data)
            setIsLoading(false)
        })
    },[loading, user, navigate])
    const handleChange = event =>{
        setTravel(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const onSubmit = async event =>{
        event.preventDefault()
        if(travel.title == null){
            alert("Document title is required")
            return
        }
        if(travel.date == null){
            alert("Date of event is required")
            return
        }
        if(selectedFile == null){
            alert("Please select a file to upload")
            return
        }
        setIsLoading(true)
        const newDoc = new FormData();
        newDoc.set("title", travel.title)
        newDoc.set("date", travel.date)
        newDoc.set("file", selectedFile)
        const resp = await doPOst("travel/save", newDoc)
        if(resp.success){
            const newDocs = await doGet("travel/all")
            setCurrent(newDocs)
            setTravel({})
            setSelectedFile(null)
            event.target.reset()

        }
        setIsLoading(false)
        alert(resp.response)
    }
    const onFocus = event =>{
        event.target.type = "date";
    }

    const onDelete = async (id) =>{
        setIsLoading(true)
        const files = await doGet("travel/delete/"+id)
        setCurrent(files)
        setIsLoading(false)
    }

    return(<div>
        <div className={"form"} onSubmit={onSubmit}>
            {Heading("Travel with Purpose Admin")}
            <div className={"login"}>
                <p>Add Document</p>
                <form>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                name="title"
                                className="text"
                                placeholder="Title"
                                value={travel.title || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type="text"
                                onFocus={onFocus}
                                className="text"
                                name="date"
                                placeholder="Date Of Event"
                                value={travel.date || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Photo" className="btn">Add Document</button>
                        </li>
                    </ul>
                </form>
                <p>Current Documents</p>
                {current.map(item=>(
                    <div>
                        <div className={"flex"}>
                            <div><b>{item.title}</b></div>
                            <div className={"trash-container"}><div onClick={()=>onDelete(item.id)}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                        </div>
                        <br/>
                    </div>
                ))}
            </div>
        </div>
        {isLoading ? Loading() : ""}
        {CopyFooter()}
    </div>)
}

export default TravelAdmin
