import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {doGet, doPOst, postJson} from "./rest";
import {hasAuthority} from "./MyCookies";
import Heading from "./Heading";
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import CopyFooter from "./CopyFooter";

function UserEdit(){
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoaded, setIdLoaded] = useState(true);
    const [count, setCount] = useState(0);
    const [userRoles, setUserRoles] = useState(false)
    const [roles, setRoles] = useState({})
    const [users, setUsers] = useState({})
    const [checked, setChecked] = useState(false);
    const [futChecked, setFutureChecked] = useState(false)
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const handleChange = () => {
        setChecked(!checked);
    };

    const handleChangeFuture = () => {
        setFutureChecked(!futChecked)
    };

    useEffect(() => {
        document.title = `Edit User | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        checkUserAdmin().then((resp)=>{
            if(!resp){
                navigate("/dashboard")
            }
            checkUserRoles().then((resp)=>{
                setUserRoles(resp)
                if(count < 1) {
                    doGet("user-admin/user/"+id).then((result) => {
                        setUsers(result)
                        if(result.admin === "1"){
                            handleChange()
                        }
                        if(result.futureResident){
                            handleChangeFuture()
                        }
                    });
                    if(resp){
                        doGet("user-admin/user-roles/"+id).then((result) => {
                                setRoles(result)
                            setIdLoaded(false)
                        });
                    }else{
                        setIdLoaded(false)
                    }
                    setCount(1);
                }
            })
        })
    }, [user, loading]);

    const checkUserAdmin = async () =>{
        return await hasAuthority("ROLE_users")
    }

    const checkUserRoles = async () =>{
        return await hasAuthority("ROLE_usersPermissions")
    }

    const deleteUser = async () =>{
        if(window.confirm("Are you sure you want to delete this user?  It cannot be undone.")){
            setIdLoaded(true)
            let response = await doGet("user-admin/delete-account/"+users.uid)
            alert(response.response)
            if(response.success){
                navigate("/user-admin")
            }
            setIdLoaded(false)
        }
    }
    const checkBoxListChange = event =>{
        let data = event.target.name
        let newVal = "1"
        if(roles[data] === "1"){
            newVal = "0"
        }
        setRoles(prevState => ({
            ...prevState,
            [data] : newVal
        }))
    }

    const updateSecurity = async () =>{

        if(roles.uid == null){
            roles.uid = users.uid
        }
        let response = await postJson("user-admin/update-permissions", roles)
        roles.id = response.newId
        setMessage(response.response)
    }

    const updateUserInfo = async () =>{
        setIdLoaded(true)
        setMessage("")
        let formDate = new FormData();
        formDate.append("password", password)
        formDate.append("uid", users.uid)
        formDate.append("futureResident", futChecked)
        let response = await doPOst("user-admin/update-password", formDate)
        setMessage(response.response)
        if(await hasAuthority("ROLE_usersPermissions")){
            let formDate = new FormData();
            if(checked){
                formDate.append("admin", "1")
            }else{
                formDate.append("admin", "0")
            }
            formDate.append("uid", users.uid)
            let response = await doPOst("user-admin/update-isadmin", formDate)
            setMessage(message+response.response)
        }
        setIdLoaded(false)

    }

    return(
        <div>
            <div className={"form"}>
                {Heading("Edit User")}
                {isLoaded ? Loading() :<div>
                    <div className={"login"}>
                        {message}
                    <div className={"user-display-full"}>
                        <div> <strong>Name: </strong></div>
                        <div>{users.fname} {users.lname}</div>
                    </div>
                    <div className={"user-display-full"}>
                        <div> <strong>email: </strong></div>
                        <div>{users.email}</div>
                    </div>
                    <div className={"user-display-full"}>
                        <div> <strong>uid: </strong></div>
                        <div>{users.uid}</div>
                    </div>
                    <div className={"user-display-full"}>
                        <div> <strong>Registered Date: </strong></div>
                        <div>{users.regdate}</div>
                    </div>
                        {userRoles ? <div className={"user-display-full"}>
                            <div> <strong>Is Admin: </strong></div>
                            <div><input type={"checkbox"} value={"1"} checked={checked} onChange={handleChange}/></div>
                        </div> : <br/>}
                        <div className={"user-display-full"}>
                        <div> <strong>Future Resident: </strong></div>
                        <div><input type={"checkbox"} value={1} checked={futChecked} onChange={handleChangeFuture}/></div>
                        </div>
                        <br/>
                    <div className={"flex-beside"}>
                        <span className={"un"}>
                            <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                        </span>
                        <input
                        type={"text"}
                        className={"text"}
                        name={"password"}
                        placeholder={"Enter Password To Update"}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>
                        <br/>
                        <button name="gsvradd" value="Update" className="btn" onClick={updateUserInfo}>Update User</button>
                    </div>
                    <br/>
                    <div className="sign logout-container flex">
                        <a className={"button-icon"} href="javascript:void(0)" onClick={deleteUser}>
                            <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon><br/>
                            Delete User
                        </a>
                    </div>
                </div> }
                {userRoles ? <div className={"login"}>
                    <h2>User Roles</h2>
                    <div className={"user-display-full"}>
                        <p>Community Events: </p>
                        <input type={"checkbox"}
                               name={"communityEvents"}
                               checked={roles.communityEvents === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Dining Menus: </p>
                        <input type={"checkbox"}
                               name={"diningMenus"}
                               checked={roles.diningMenus === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Garden Variety: </p>
                        <input type={"checkbox"}
                               name={"gardenVariety"}
                               checked={roles.gardenVariety === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Media: </p>
                        <input type={"checkbox"}
                               name={"media"}
                               checked={roles.media === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Meeting Minutes: </p>
                        <input type={"checkbox"}
                               name={"meetingMinutes"}
                               checked={roles.meetingMinutes === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Resident Documents: </p>
                        <input type={"checkbox"}
                               name={"files"}
                               checked={roles.files === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Travel With Purpose: </p>
                        <input type={"checkbox"}
                               name={"travelWithPurpose"}
                               checked={roles.travelWithPurpose === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>The Weekly: </p>
                        <input type={"checkbox"}
                               name={"weekly"}
                               checked={roles.weekly === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Users: </p>
                        <input type={"checkbox"}
                               name={"users"}
                               checked={roles.users === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Users Permissions: </p>
                        <input type={"checkbox"}
                               name={"usersPermissions"}
                               checked={roles.usersPermissions === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <div className={"user-display-full"}>
                        <p>Banner Admin: </p>
                        <input type={"checkbox"}
                               name={"bannerAdmin"}
                               checked={roles.bannerAdmin === "1"}
                               onChange={checkBoxListChange}
                        />
                    </div>
                    <br/>
                    <button name="gsvradd" value="Update" className="btn" onClick={updateSecurity}>Update Security</button>
                </div> : <div></div>}

            </div>
            {CopyFooter()}
        </div>
    )

}

export default UserEdit
