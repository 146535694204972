import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useReducer, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Heading from "./Heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {faComment, faFile} from "@fortawesome/free-solid-svg-icons";
import {doGet, doPOst} from "./rest";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import React from 'react';

function AddMarketplace(){
    const formReducer = (state, event) =>{
        return{
            ...state,
            [event.name] : event.value
        }
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputs, setInputs] = useReducer(formReducer, {});
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [runOnce, setRunOnce] = useState(0)
    const [expired, setExpired] = useState(0)
    let { id } = useParams();
    const submitForm = async ()=>{
        if(id != null){
            setIsLoading(true)
            const formDate = new FormData();
            formDate.set("id", inputs.id)
            formDate.set("uid", inputs.uid)
            formDate.set("link", inputs.link)
            formDate.set("ptitle", inputs.ptitle)
            formDate.set("pprice", inputs.pprice)
            formDate.set("pdescription", inputs.pdescription)
            formDate.set("pimage", inputs.pimage)
            formDate.set("pthumb", inputs.pthumb)
            formDate.set("postDate", inputs.postDate)
            formDate.set("deleted", "")
            if(selectedFile != null){
                formDate.set("file", selectedFile)
            }
            const response = await doPOst("marketplace/update", formDate)
            alert(response.response)
            if (response.success) {
                navigate("/marketplace")
            }
            setIsLoading(false)
        }else {
            setIsLoading(true)
            let formData = new FormData();
            formData.append("ptitle", inputs.ptitle)
            formData.append("pprice", inputs.pprice)
            formData.append("pdescription", inputs.pdescription)
            formData.append("file", selectedFile)
            let response = await doPOst("marketplace/save", formData)
            setIsLoading(false)
            alert(response.response)
            if (response.success) {
                navigate("/marketplace")
            }
        }

    }




    useEffect(() => {
        document.title = id != null ? `Update Listing | The Marketplace | ${process.env.REACT_APP_TITLE}` : `Add Listing | The Marketplace | ${process.env.REACT_APP_TITLE}`
        if(loading){
            // show loading screen
            return;
        }
        if(!user) navigate("/");
        if(runOnce === 0) {
            if (id != null) {
                setIsLoading(true)
                doGet("marketplace/item-edit/"+id).then((res)=>{
                    if(res.id == null){
                        alert("You do not have permission to edit this item.")
                        navigate("/dashboard")
                    }
                    inputs.id = res.id
                    inputs.uid = res.uid
                    inputs.link = res.link
                    inputs.ptitle = res.ptitle
                    inputs.pprice = res.pprice
                    inputs.pdescription = res.pdescription;
                    inputs.pimage = res.pimage
                    inputs.pthumb = res.pthumb
                    inputs.postDate = res.postDate
                    inputs.deleted = res.deleted
                    setIsLoading(false)
                })
            }
            doGet("marketplace/expired").then((resp)=>{
                setExpired(resp.length)
            })
            setRunOnce(1)
        }
    },[user, loading, navigate, id, runOnce, inputs]);

    const removeItem = async () =>{
        if(window.confirm("Are you sure you want to remove this listing?  It cannot be undone.")){
            setIsLoading(true)
            const response = await doGet("marketplace/delete/"+inputs.id)
            alert(response.response)
            if(response.success){
                navigate("/marketplace")
            }
            setIsLoading(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const handleChange = event => {
        setInputs({
            name: event.target.name,
            value: event.target.value,
        });
    }

    return(<div>
        <div className={"form"}>
            {Heading("Add Post")}
            <div className={"login"}>
                <form onSubmit={handleSubmit}>
                    <ul>
                        <li className={"flex"}>
                            <span className="un">
                            <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="ptitle"
                                className="text"
                                placeholder="Post Title"
                                value={inputs.ptitle || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                            <FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>
                            </span>
                            <input
                                type="number"
                                name="pprice"
                                className="text"
                                placeholder="Price"
                                value={inputs.pprice || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>
                        <li className={"flex"}>
                            <span className="un">
                            <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
                            </span>
                            <input
                                type="text"
                                name="pdescription"
                                className="text"
                                placeholder="Description"
                                value={inputs.pdescription || ""}
                                onChange={handleChange}
                                required
                            />
                        </li>

                        <li className={"flex-beside"}>
                            <span className="un">
                            <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
                            </span>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                required={id == null}
                            />
                        </li>
                        <li>
                            <button name="gsvradd" value="Add Post" className="btn" onClick={submitForm}>{id != null ? "Update Marketplace Item" : "Add Item To Marketplace"}</button>
                        </li>
                    </ul>
                </form>
                {id != null ?<div>
                    <p>Current Image</p>
                    <img src={process.env.REACT_APP_URL+"/uploads/marketplace/full/"+inputs.pimage} width={300} alt={"img"}/>
                    <p>To update your image, simply upload a new one.</p>
                    <br/>
                    <ul>
                        <li>
                            <button name="gsvradd" value="Add Post" className="btn" onClick={()=>removeItem()}>Remove from Marketplace</button>
                        </li>
                    </ul>
                </div>:""}
                {expired > 0 ?<a href={"/marketplace/expired"}>Your Expired Listings</a> :""}
            </div>
            {isLoading ? Loading() : ""}
        </div>
        {CopyFooter()}
    </div>)



}

export default AddMarketplace
