import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGetText} from "./rest";
import {Loading} from "./Loading";
import React from 'react';

function FullResident() {
    let { type } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [html, setHtml] = useState(<></>)
    const [isLoading, setIsLoading] = useState(true)
    useEffect(()=>{
        document.title = `Directory | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        doGetText("celebrations/directory/"+type).then((data)=>{
            setHtml(data)
            setIsLoading(false)
            document.body.className = ""
            document.getElementsByClassName("App").item(0).className = ""
        })
    },[loading, user, navigate])

    if(isLoading){
        return (
            <div>
                {Loading()}
            </div>
        )
    }

  return(<div dangerouslySetInnerHTML={{__html: html}}>
    </div>)




}
export default FullResident
