import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleInfo, faCalendar, faPhotoFilm} from '@fortawesome/free-solid-svg-icons'
import {useEffect, useState, useReducer} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import {doPOst} from "./rest";
import React from 'react';


function PhotoGalleryAdd(){

    const formReducer = (state, event) =>{
        return{
            ...state,
            [event.name] : event.value
        }
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputs, setInputs] = useReducer(formReducer, {});
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const submitForm = async () =>{
        if(inputs.ptitle == null){
            alert("Title is required")
            return
        }
        if(inputs.date == null){
            alert("date taken is required")
            return
        }
        if(selectedFile == null){
            alert("Please select your photo to upload")
            return
        }
        let formData2 = new FormData();
        formData2.append("ptitle", inputs.ptitle);
        formData2.append("date", inputs.date);
        formData2.append("file", selectedFile);
        let resp = await doPOst("gallery/save", formData2);
        alert(resp.response);
        if(resp.success){
            navigate("/gallery")
        }
    }




    const handleChange = event => {
        setInputs({
            name: event.target.name,
            value: event.target.value,
        });
    }
    const onFocus = event =>{
        event.target.type = "date";
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }
    useEffect(() => {
        document.title = `Add Photo | Photo Gallery | ${process.env.REACT_APP_TITLE}`
        if(loading){
            // show loading screen
            return;
        }
        if(!user) navigate("/");
    },[user, loading]);
    return(
        <div>
            <div className="form">
                {Heading("Add Photo")}
                <div className="login">
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="ptitle"
                                    className="text"
                                    placeholder="Photo Title"
                                    value={inputs.ptitle || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    onFocus={onFocus}
                                    className="text"
                                    name="date"
                                    placeholder="Date Taken"
                                    value={inputs.date || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex-beside">
                                <span className="un">
                                    <FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="file"
                                    name="file"
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                    required
                                />
                            </li>
                            <li>
                                <button name="gsvradd" value="Add Photo" className="btn" onClick={submitForm}>Add Photo</button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            {CopyFooter()}
        </div>
    )
}

export default PhotoGalleryAdd
