import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import React from 'react';
function MicroCommunity() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    useEffect(()=>{
        document.title = `Micro Communities | ${process.env.REACT_APP_TITLE}`;
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        doGet(`micro-communities/${id}`).then((data)=>{
            setIsLoading(false)
            setPageData(data)
        })
    },[loading, user, navigate, id])

    return(<div>
        <div className={"form"}>
        {Heading(pageData.title ?? "")}
        <div className={"login"}>
            {isLoading ? Loading() : <div dangerouslySetInnerHTML={{__html: pageData.data}}></div>}
        </div>
        </div>
        {CopyFooter()}
    </div>);
}
export default MicroCommunity
