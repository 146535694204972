import React, {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import Heading from "./Heading";
import CopyFooter from "./CopyFooter";
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faEdit, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {doGet, postJson} from "./rest";

function BannerAdmin(){

    let { id } = useParams();

    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false);

    const [message, setMessage] = useState({})

    const [currentMessage, setCurrentMessage] = useState({})

    const [upcoming, setUpcoming] = useState([])

    useEffect(()=>{
        document.title = `Banner Admin | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_bannerAdmin").then((resp)=>{
            if(!resp){
                navigate("/")
            }
        })
        if(id != null){
            doGet("top-messaging/edit/"+id).then((resp)=>{
                setMessage(resp)
            })
        }
        console.log("looping")
        loadMessages()
    },[loading, user, id])

    const loadMessages = async () =>{
        const resp = await doGet("top-messaging/current")
        if(resp.current != null){
            setCurrentMessage(resp.current)
        }else{
            setCurrentMessage({})
        }
        setUpcoming(resp.upcoming)
    }

    const onFocus = event =>{
        event.target.type = "date";
    }

    const handleChange = event =>{
        setMessage(prevState =>({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleChangeHtml = (html) => {
        setMessage(prevState =>({
            ...prevState,
            "message": html
        }))
    }

    const addBanner = async event =>{
        event.preventDefault()
        setIsLoaded(true)
        const resp = await postJson("top-messaging/save", message)
        alert(resp.response)
        if(resp.success){
            if(id == null){
                await loadMessages()
                setMessage({})
            }else{
                setMessage({})
                navigate("/banner-admin")
            }

        }
        setIsLoaded(false)
    }

    const deleteItem = async (id) =>{
        if(window.confirm("Are you sure you want to delete this?")){
            setIsLoaded(true)
            const resp = await doGet("top-messaging/delete/"+id)
            alert(resp.response)
            if(resp.success){
                await loadMessages()
            }
            setIsLoaded(false)
        }
    }

    const formatDate = (date) =>{
        const dates = date.split("-");
        return dates[1]+"/"+dates[2]+"/"+dates[0]
    }

    const editItem = (id) =>{
        navigate("/banner-admin/"+id)
    }

    return (<div>
        <div className={"form"}>
            {Heading("Manage Banner")}
            <div className={"login"}>
                <p>{id == null ? "Add Message" : "Edit Message"}</p>
                <form>
                    <ul>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type={id == null ? "text" : "date"}
                                name="startDate"
                                className="text"
                                placeholder="Enter Start Date"
                                onFocus={onFocus}
                                value={message.startDate || ""}
                                onChange={handleChange}
                                readOnly={id != null}
                                required
                            />
                        </li>
                        <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                            <input
                                type={id == null ? "text" : "date"}
                                name="endDate"
                                className="text"
                                onFocus={onFocus}
                                placeholder="Enter End Date"
                                value={message.endDate || ""}
                                onChange={handleChange}
                                readOnly={id != null}
                                required
                            />
                        </li>
                        <li style={{height:100}}>
                            <ReactQuill theme="snow" placeholder={"Enter Message"} value={message.message} onChange={handleChangeHtml} />
                        </li>
                        <button name="gsvradd" value="Update" className="btn" onClick={addBanner}>{id == null ? "Add Banner" : "Save Changes"}</button>
                    </ul>
                </form>
                <p>Current Banner</p>
                {currentMessage.message == null ? <p>There are currently no banners scheduled.</p>:<div className={"flex"}>
                    <div>
                        <div style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: currentMessage.message}}></div>
                        <p>will display from {formatDate(currentMessage.startDate)} until {formatDate(currentMessage.endDate)}</p>
                    </div>
                    <div className={"trash-container padding-left"}><div onClick={() => editItem(currentMessage.id) }><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></div></div>
                    <div className={"trash-container padding-left"}><div onClick={() => deleteItem(currentMessage.id) }><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                </div> }
                <p>Upcoming Banners</p>
                {upcoming.map(items =>(
                    <div className={"flex"}>
                        <div>
                        <div style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: items.message}}></div>
                            <p>will display from {formatDate(items.startDate)} until {formatDate(items.endDate)}</p>
                        </div>
                        <div className={"trash-container padding-left"}><div onClick={() => editItem(items.id) }><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></div></div>
                        <div className={"trash-container padding-left"}><div onClick={() => deleteItem(items.id) }><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></div></div>
                    </div>
                ))}
            </div>
        </div>
        {isLoaded ? Loading() : ""}
        {CopyFooter()}
    </div>)



}

export default BannerAdmin
