import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {hasAuthority} from "./MyCookies";
import {doGet, postJson} from "./rest";
import Heading from "./Heading";
import {Loading} from "./Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faHomeLgAlt, faHotTub, faVideo} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import CopyFooter from "./CopyFooter";

function EditMedia() {
    let { id } = useParams();
    const [user, loading] = useAuthState(auth);
    const [media, setMedia] = useState({})
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();
    const [count, setCount] = useState(0);

    useEffect(()=>{
        document.title = `Edit Media | ${process.env.REACT_APP_TITLE}`
        if (loading) return;
        if (!user) return navigate("/");
        hasAuthority("ROLE_media").then((resp) =>{
            if(!resp){
                navigate("/dashboard")
            }
        })
        if(count < 1){
           loadMedia()
            setCount(1)
        }
    },[user,loading, navigate])

    const loadMedia = async () =>{
        const resp = await doGet("media/load/"+id)
        setMedia(resp.media)
        setCategories(resp.categories)
        setIsLoading(false)
    }

    const handleChange = event =>{
        setMedia(prevState =>({
            ...prevState,
            [event.target.name] : event.target.value
        }))
    }

    function validateUrl(urlString){
        try {
            new URL(urlString);
        } catch (_) {
            return false;
        }
        return true;
    }

    const handleSubmit = async event =>{
        event.preventDefault()
        if(media.title.length === 0){
            alert("Title is required")
            return
        }
        if(!validateUrl(media.url)){
            alert("Valid url is required.")
            return
        }
        if(media.cat == null){
            alert("Please select a category")
            return
        }
        setIsLoading(true)
        await postJson("media/new-media", media)
        setIsLoading(false)
        alert("Media post has been updated")
    }

    return(<div>
        <div className={"form"}>
            {Heading("Edit Entry")}
            <div className="login">
                {isLoading ? Loading() :<div>
                    <form onSubmit={handleSubmit}>
                        <ul>

                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="title"
                                    className="text"
                                    placeholder="Media Title"
                                    value={media.title || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>
                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faHotTub}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="text"
                                    name="url"
                                    className="text"
                                    placeholder="Media Url"
                                    value={media.url || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>

                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                                </span>
                                <input
                                    type="date"
                                    className="text"
                                    name="date"
                                    placeholder="Post Date"
                                    value={media.date || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </li>

                            <li className="flex">
                                <span className="un">
                                    <FontAwesomeIcon icon={faHomeLgAlt}></FontAwesomeIcon>
                                </span>
                                <select className={"text"} name={"cat"} value={media.cat} onChange={handleChange}>
                                    {categories.map(item=>(
                                        <option value={item.id} selected={item.id === media.cat}>{item.title}</option>
                                    ))}
                                </select>
                            </li>
                            <li>
                                <button name="gsvradd" value="Add Photo" className="btn">Edit Media</button>
                            </li>
                        </ul>
                    </form>
                </div>}
            </div>
        </div>
        {CopyFooter()}
    </div>)

}
export default EditMedia
