import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import {useEffect, useState} from "react";
import {doGet} from "./rest";
import {useNavigate} from "react-router-dom";
import Heading from "./Heading";
import {Loading} from "./Loading";
import CopyFooter from "./CopyFooter";
import React from 'react';
import {faFolder, faFileText} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {hasAuthority} from "./MyCookies";


function Files(){
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [fileAdmin, setFileAdmin] = useState(false)
    useEffect(()=>{
        document.title = `Resident Documents | ${process.env.REACT_APP_TITLE}`
        if(loading){
            return;
        }
        if (!user) return navigate("/");
        if(count < 1) {
            doGet("files/").then((result) => {
                setData(result);
                setIsLoading(false);
            });
            setCount(1)
        }
        hasAuthority("ROLE_files").then((res)=>{
           setFileAdmin(res)
        })
    }, [user, loading, count, navigate])

    const renderBody = () =>{
        return (<div>
            <h2>Folders</h2>
            {data["folders"].map(items=>(
                <div className="flex-filefolders"><FontAwesomeIcon icon={faFolder}></FontAwesomeIcon>
                    <a className="folder-padding" href={"/folder/"+items.uid}>{items.title}</a>
                </div>
            ))}
            <h2>Files</h2>
            {data["files"].map(items=>(
                <div className="flex-filefolders"><FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
                    <a rel="noreferrer noopener" className="folder-padding" href={process.env.REACT_APP_URL+"/pdf/web/viewer.html?file=/files/"+items.url} target="_blank">{items.title}</a>
                </div>
            ))}
        </div>)
    }

    return(<div>
        <div className={"form"}>
            {Heading("Resident Documents")}
            {isLoading ? Loading() : <div className={"login"}>
                <p>See the following forms and documents</p>
                {renderBody()}
            </div>}

        </div>
        {fileAdmin ? <div className="sign logout-container flex">
            <a className={"button-icon"} href="/documents-admin">
                <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon><br/>
                Documents Admin
            </a>
        </div> : ""}
        {CopyFooter()}
    </div>)

}

export default Files
