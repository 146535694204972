import React, {useEffect} from "react";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CopyFooter from "./CopyFooter";

function PageNotFound() {

    useEffect(()=>{
        document.title = "ERROR 404"
    })

    const goback = ()=>{
        window.history.go(-1)
    }

    return(<div>
        <div className={"form"}>
            <div className="header">
                <h2>ERROR 404: Page Not Found</h2>
            </div>
            <div className={"login"}>
                <div className={"react-responsive-modal-containerCenter"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={"errorf"}></FontAwesomeIcon>
                </div>
                <br/>
                Oops, something went wrong. Either the page you are trying to access isn't valid or it no longer exists.
                <br/><br/>
                <a href={"javascript:void(0)"} onClick={()=>goback()}>Go Back</a>
                <br/><br/><br/>
            </div>
        </div>
        {CopyFooter()}
    </div>)

}
export default PageNotFound