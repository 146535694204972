import BackButton from "./BackButton";
import React from 'react';


function CopyFooter(){
    return(<div>
        {BackButton("/dashboard")}
            <div id="copyfooter">©{(new Date().getFullYear())} {process.env.REACT_APP_TITLE} | <a href="/terms-and-conditions">Terms &amp; Conditions</a> | <a href="/privacy-policy">Privacy Policy</a></div></div>
    );
}
export default CopyFooter
